import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import SuperSidebar from "../Components/SiderbarAllowFeatures/SuperAgent";
import axios from "axios";
import { toast } from "react-toastify";

export default function ClickSingleSessionDesscion() {
  const [data, setData] = React.useState([]);
  let token = localStorage.getItem("token");
  const location = useLocation();
  const { item } = location.state;
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  useEffect(() => {
    getList();
  }, []);
  function getList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}owner/inplay-sessions`, options)
      .then(async (resp) => {
        const data = resp.data.sessions;
        const TempArr = [];
        data.map((sessionData) => {
          if (sessionData.matchId == item) {
            TempArr.push(sessionData);
            setData(TempArr);
          }
        });
      });
  }
  const MainFun = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    //  console.log("Formvlaues", Formvlaues)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}owner/declare-session`,
        Formvlaues,
        options
      )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          e.target.reset();
          getList();
        } else {
          toast.warning(response.data.message);
          console.log(response.data.message);
        }
      })
      .catch((e) => {
        console.log("error", e.message);
      });
  };
  return (
    <>
      <SuperSidebar />
      <div className="content-wrapper">
        <div className="container-fluid content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 ml-2">
                <i class="fa fa-home"></i> All Session
              </h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  {" "}
                  All Session Decission
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Session Name </th>
                        <th>Dession Run /Submit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? (
                        data.map((e, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{e.nat}</td>
                              <td>
                                <div className="row">
                                  <form onSubmit={(e) => MainFun(e)}>
                                    <input
                                      type="Number"
                                      name="decision_run"
                                      className="form-control input-lg"
                                      min="0"
                                      oninput="this.value = Math.abs(this.value)"
                                    />
                                    <input
                                      type="hidden"
                                      name="sid"
                                      value={e.sid}
                                      readOnly=""
                                      className="readonly"
                                    />
                                    <input
                                      type="hidden"
                                      name="matchid"
                                      value={e.matchId}
                                      readOnly=""
                                      className="readonly"
                                    />
                                    <button
                                      class="float-right btn btn-success  m-2"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </form>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="text-center">
                          <td colSpan={4}>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
