import React from "react";
import "./CompleteSubPages.css";
import { Link, useLocation } from "react-router-dom";

export default function CompleteMatchSessionPlusMinus() {
  const location = useLocation();
  let gameData = [];
  if (location.state) {
    gameData = location.state;
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Session Plus Minus</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/complete-games">Complete Games</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Session Plus Minus</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0 card-title">{gameData.name}</h4>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <span>
                    <b>Preference</b>
                  </span>
                  <select className="form-control mx-3 col-4">
                    <option>Both</option>
                    <option>Session</option>
                  </select>
                  <Link
                    className="btn btn-primary"
                    to={{
                      pathname: "/complete-games/session-list-plus-minus",
                    }}
                    state={gameData}
                  >
                    Show
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
