import React from "react";
import "./adminsidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faPlay,
  faChevronRight,
  faDatabase,
  faSignInAlt,
  faClipboardList,
  faUserSecret,
  faThList,
  faIndianRupeeSign,
  faChartLine,
  faSignal,
  faChartColumn,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleStop } from "@fortawesome/free-regular-svg-icons";

export default function AdminSidebar({ toggleSidebar }) {
  return (
    <>
      <li className="nav-header">Master Details</li>
      <li className="nav-item">
        <Link to="collection-list" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faIndianRupeeSign} />
          <p>Collection Master</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link to="power-users" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faUserSecret} />
          <p>Power Users</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="supermaster" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Super Admin</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="sub-admin" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Sub Admin</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="masters" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Master</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="superagents" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Super Agent</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="agent" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Agent</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="client" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
          <p>Client</p>
        </Link>
      </li>
      <li className="nav-header">Games</li>
      <li className="nav-item">
        <Link to="/inplay" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faPlay} />
          <p>In Play</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faCircleStop} />
          <p>Complete Games</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/casino-in-play" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faPlay} />
          <p>Casino InPlay</p>
        </Link>
      </li>

      <li className="nav-header">Casino</li>

      <li
        className="nav-item"
        data-bs-toggle="collapse"
        href="#collapseExample"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <a className="nav-link">
          <FontAwesomeIcon className="nav-icon" icon={faChartLine} />
          <p>Live Casino Position</p>
        </a>

        <ul className="nav flex-column collapse ps-4" id="collapseExample">
          <li className="nav-item">
            <Link
              to="virtual-teen-patti"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faSignal} />
              <p>VirtualTeenPosition</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/live-teen-patti"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faChartColumn} />
              <p>LiveTeen20Position</p>
            </Link>
          </li>
        </ul>
      </li>

      <li className="nav-item">
        <Link
          to="/casino-details"
          className="nav-link text-sm"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faWallet} />
          <p>Casino Details</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/casino-bet-report"
          className="nav-link text-sm"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChartLine} />
          <p>Int. Casino Details</p>
        </Link>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link">
          <i className="nav-icon fas fa-tachometer-alt" />
          <p>
            Casino
            <i className="right fas fa-angle-left" />
          </p>
        </aclassName=>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <Link to="teenpatti_t20" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>TEENPATTI T20</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="dragon_tiger" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>Dragon Tiger</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="lucky7_a" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>Lucky7 A</p>
            </Link>
          </li>
        </ul>
      </li> */}

      <li className="nav-header">Cash Transaction</li>
      <li className="nav-item">
        <Link to="transactions" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (C)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="agent-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (A)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="superagent-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (SA)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="master-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (M)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="subadmin-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (SB)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="admin-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Debit/Credit Entry (AD)</p>
        </Link>
      </li>

      <li className="nav-header">Ledger</li>

      {/* <li className="nav-item">
        <Link to="client-plus-minus" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>Client Plus/Minus</p>
        </Link>
      </li> */}
      <li className="nav-item">
        <Link to="client-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Client Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="agent-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Agent Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="super-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Super Agent Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="master-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Master Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="sub-admin-ledger"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Sub Admin Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="admin-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p>All Admin Ledger</p>
        </Link>
      </li>

      <li className="nav-header">Reports</li>

      <li
        className="nav-item"
        data-bs-toggle="collapse"
        href="#collapseExample2"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample2"
      >
        <a className="nav-link">
          <FontAwesomeIcon className="nav-icon" icon={faDatabase} />
          <p>Data Report</p>
        </a>

        <ul className="nav flex-column collapse ps-4" id="collapseExample2">
          <li className="nav-item">
            <Link
              to="super-admin-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Super Admin Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="sub-admin-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Sub Admin Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="master-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Master Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="super-agent-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Super Agent Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="agent-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Agent Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="client-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faThList} />
              <p>Client Report</p>
            </Link>
          </li>
        </ul>
      </li>

      <li
        className="nav-item"
        data-bs-toggle="collapse"
        href="#collapseExample"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <a className="nav-link">
          <FontAwesomeIcon className="nav-icon" icon={faSignInAlt} />
          <p>Login Report</p>
        </a>

        <ul className="nav flex-column collapse ps-4" id="collapseExample">
          <li className="nav-item">
            <Link
              to="super-admin-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Super Admin Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="sub-admin-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Sub Admin Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="master-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Master Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="super-agent-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Super Agent Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="agent-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Agent Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="client-login-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon className="nav-icon" icon={faClipboardList} />
              <p>Client Login Report</p>
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
