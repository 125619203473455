import React, { useState, useEffect } from "react";
import "./CreateNewClientMaster.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useCreateClientAccount from "../../hooks/useCreateClientAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";

export default function CreateNewClientMaster() {
  const navigate = useNavigate();
  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  const { mutate: createAccount } = useCreateAccount();
  const { mutate: createClientAccount } = useCreateClientAccount();
  const { mutate: updateLimit, isLoading: isUpdatingLimit } = useUpdateLimit();
  const [clientName, setClientName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [clientLimit, setClientLimit] = useState(0);
  const [clientMobileShare, setClientMobileShare] = useState(0);
  const [clientCommission, setClientCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [clientMatchCommission, setClientMatchCommission] = useState(0);
  const [clientSessionCommission, setClientSessionCommission] = useState(0);
  const [clientCassinoCommission, setClientCassinoCommission] = useState(0);

  useEffect(() => {
    setClientPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setClientPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "user",
      name: clientName,
      reference: clientReference,
      password: String(clientPassword),
      parent: parentData.id,
      mobile: clientNumber,
      limit: clientLimit,
      mobile_share: clientMobileShare,
      commission: clientCommission,
      match_commission: clientMatchCommission,
      session_commission: clientSessionCommission,
      cassino_commission: clientCassinoCommission,
    };

    createClientAccount(payload, {
      onSuccess: (data) => {
        console.log(data);
        const clientLimitPayload = {
          user_id: data.data.data.id,
          acc_type: "user",
          limit: clientLimit,
          coins: clientLimit,
          types: "credit",
          description: "Client Limit Update",
        };
        const parentLimitPayload = {
          user_id: parentData.id,
          acc_type: "agent",
          limit: parentData.limit - clientLimit,
          coins: clientLimit,
          types: "debit",
          description: "Agent Limit Update",
        };
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            updateLimit(clientLimitPayload, {
              onSuccess: () => {
                navigate("/client");
                toast.success("Client created successfully!");
              },
              onError: (error) => {
                console.error(error);
              },
            });
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        toast.error("Something went wrong! Please try again later.");
        console.error(error);
      },
    });
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Client </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/client">Client</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Client</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="clientCode">
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="clientCode"
                            name="clientCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="clientName"
                            name="clientName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setClientName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="clientNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="clientNumber"
                            name="clientNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setClientNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="clientReference"
                            name="clientReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setClientReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="clientPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="clientPassword"
                              name="clientPassword"
                              className="form-control"
                              required
                              value={clientPassword}
                              onChange={(e) => {
                                setClientPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Client Limit</label>
                            <input
                              type="number"
                              id="clientLimit"
                              name="clientLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.limit}
                              required
                              onChange={(e) => {
                                setClientLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.share}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="clientCommission"
                              name="clientCommission"
                              required
                              onChange={(e) => {
                                setClientCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Match Commission
                            </label>
                            <input
                              type="number"
                              id="clientMatchCommission"
                              name="clientMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.match_commission}
                              required
                              onChange={(e) => {
                                setClientMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Session Commission
                            </label>
                            <input
                              type="number"
                              id="clientSessionCommission"
                              name="clientSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.session_commission}
                              required
                              onChange={(e) => {
                                setClientSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="clientCassinoCommission"
                              name="clientCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_commission}
                              required
                              onChange={(e) => {
                                setClientCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/client");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Create New Client
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
