export const dateTimeFormatter = (date) => {
  const format = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  return new Date(date).toLocaleString("en-US", format).replace(" at", ",");
};

export const dateFormatter = (date) => {
  const format = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };

  return new Date(date).toLocaleString("en-US", format);
};
