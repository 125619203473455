import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import SuperSidebar from "../Components/SiderbarAllowFeatures/SuperAgent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function MatchDesscion() {
  const [data, setData] = React.useState([]);
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}owner/inplay-matches`, options)
      .then(async (resp) => {
        console.log(resp.data.matches);
        setData(resp.data.matches);
      });
  }

  const myFormData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    // console.log("form data is == ", Formvlaues);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}owner/match-decision`,
      Formvlaues,
      options
    );
    const datamsg = response.data;
    if (response.data.success) {
      const data = response.data;
      if (data.success) {
        //navigate(`/ poll`);
        toast.success(data.message);
        e.target.reset();
        getList();
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error(datamsg.message);
    }
  };

  return (
    <>
      <SuperSidebar />
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 ml-2">
                <i class="fa fa-home"></i> All Matches
              </h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active"> All Matches</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div class="d-grid  gap-2 mt-2 d-md-flex justify-content-around  align-items-center">
                  <span>
                    <i class="fa fa-globe"></i> Sport Master All Matches Back{" "}
                  </span>
                  <button class="btn btn-success me-md-2" type="button">
                    Go to Session Decision
                  </button>
                  <button class="btn btn-info" type="button">
                    Match Book
                  </button>
                  <button class="btn btn-primary" type="button">
                    Get new session
                  </button>
                </div>

                <div className="card-body">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Match Name </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{e.match_name}</td>
                            <td>
                              <form
                                className="d-flex align-items-center"
                                onSubmit={(e) => myFormData(e)}
                              >
                                <input
                                  type="hidden"
                                  name="gameId"
                                  value={e.game_id}
                                />
                                <select
                                  className="form-control select-style"
                                  name="teamName"
                                  required
                                >
                                  <option value="" disabled selected>
                                    Select...
                                  </option>
                                  <option value={e.first_team_name}>
                                    {e.first_team_name}
                                  </option>
                                  <option value={e.second_team_name}>
                                    {e.second_team_name}
                                  </option>
                                </select>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </form>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
