import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageHeader from "../../Components/PageHeader";

import { dateTimeFormatter } from "../../utils/dateFormatter";

export default function AgentDataReport() {
  const userId = localStorage.getItem("UUID");
  const loggedAccType = localStorage.getItem("account");

  const [data, setData] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [completeLoginData, setCompleteLoginData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(loginData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = formData.get("user");
    const from = formData.get("from");
    const to = formData.get("to");

    if (user === "" && from === "" && to === "") {
      setLoginData(completeLoginData);
    } else {
      const filteredData = completeLoginData.filter((item) => {
        if (user !== "" && from !== "" && to !== "") {
          return (
            item.user_agent.id === user &&
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "" && from !== "") {
          return (
            item.user_agent.id === user &&
            new Date(item.createdAt) >= new Date(from)
          );
        } else if (user !== "" && to !== "") {
          return (
            item.user_agent.id === user &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (from !== "" && to !== "") {
          return (
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "") {
          return item.user_agent.id === user;
        } else if (from !== "") {
          return new Date(item.createdAt) >= new Date(from);
        } else if (to !== "") {
          return new Date(item.createdAt) <= new Date(to);
        }
      });
      setLoginData(filteredData);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loginData.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else if (pageNumber === Math.ceil(loginData?.length / itemsPerPage)) {
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(loginData?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 === Math.ceil(loginData?.length / itemsPerPage)
      );
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Casino Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card ">
              <div className="card-header bg-purple">
                <h1>Casino Bet Report</h1>
                <div className="container-fluid">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <lable>Client</lable>
                          <select className="form-select w-100" name="user">
                            <option value="">Select</option>
                            {data &&
                              data?.length !== 0 &&
                              data?.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.code} {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Date From</label>
                          <input
                            type="date"
                            name="from"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Date To</label>
                          <input
                            type="date"
                            name="to"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3 d-flex">
                        <div className="form-group w-100 align-self-end">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>GameName</th>
                        <th>Round Id</th>
                        <th>Runner</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loginData && loginData?.length !== 0 ? (
                        <>
                          {currentItems?.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.user_agent.code}</td>
                                  <td>{item.user_agent.name}</td>
                                  <td>{item.old_balance}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.types}</td>
                                  <td>{item.types}</td>
                                  <td>{dateTimeFormatter(item.createdAt)}</td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr className="bg-white">
                            <td colSpan="8">
                              <h6 className="text-center">
                                There are no records to display
                              </h6>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeLoginData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {pageNumbers?.map((number, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => {
                                if (number !== "...") {
                                  paginate(number);
                                }
                              }}
                              className={`page-link ${
                                currentPage === number ? "active-page-link" : ""
                              } ${number === "..." ? "disabled" : ""}`}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
