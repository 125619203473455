/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useGetSessionOfMatch from "../../hooks/Games/Inplay/useGetSessionOfMatch";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";

export default function InplaySessionListPlusMinus() {
  const location = useLocation();

  let gameData = [];
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");
  if (location.state) {
    gameData = location.state;
  }

  const { mutate: getSessionOfMatch } = useGetSessionOfMatch();
  const { mutate: getBetsData } = useGetBetsData();

  const [sessionData, setSessionData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const payload = {
      match_id: gameData.id,
    };

    getSessionOfMatch(payload, {
      onSuccess: (data) => {
        let session_exist = [];
        let all_sessions = [];

        data.data.forEach((element) => {
          if (!session_exist.some((el) => el === element.session)) {
            all_sessions.push(element);
            session_exist.push(element.session);
          }
        });
        setSessionData(all_sessions);
      },
      onError: (error) => {
        console.log(error);
      },
    });

    const betsPayload = {
      match_id: gameData.id,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(betsPayload, {
      onSuccess: (data) => {
        let matchUser = [];

        data.data.forEach((el) => {
          if (!matchUser.some((ele) => ele.id === el.user_accounts[0].id)) {
            matchUser.push({
              code: el.user_accounts[0].code,
              name: `${el.user_accounts[0].name}`,
              id: el.user_accounts[0].id,
            });
          }
        });

        setUserData(matchUser);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session List Plus Minus</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Session List Plus Minus</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mb-0 card-title">{gameData.name}</div>
                  <Link
                    className="btn btn-primary"
                    to={`/complete-games/all-company-report?id=${gameData.id}&team=0`}
                  >
                    Show
                  </Link>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row align-items-start">
                  <div className="col-md-8">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" defaultChecked={true} />
                          </th>
                          <th>SESSION (POST DATE)</th>
                          <th>DECLARE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData.length !== 0 ? (
                          sessionData.map((session, index) => (
                            <tr key={index}>
                              <td className="p-3">
                                <input type="checkbox" defaultChecked={true} />
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {session.session}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {session.declare}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" defaultChecked={true} />
                          </th>
                          <th>Name (CODE)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.length !== 0 ? (
                          userData.map((user, index) => (
                            <tr key={index}>
                              <td className="p-3">
                                <input type="checkbox" defaultChecked={true} />
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {user.name} ({user.code})
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
