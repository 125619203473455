import React, { useState, useEffect } from "react";
import "./cashtransaction.css";
import { toast } from "react-toastify";
import PageHeader from "../../Components/PageHeader";
import Card from "../../Components/Card";
import useGetSuperAdminList from "../../hooks/SuperAdmin/useGetSuperAdminList";
import useGetCashData from "../../hooks/useGetCashData";
import useCreateCashLedger from "../../hooks/useCreateCashLedger";
import { dateFormatter } from "../../utils/dateFormatter";
import Select from "react-select";

export default function SuperAdminCashTransaction(props) {
  const { data: superAdminListData } = useGetSuperAdminList();
  const { mutate: getCashData } = useGetCashData();
  const { mutate: createCashLedger } = useCreateCashLedger();

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [cashData, setCashData] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const loggedAccount = localStorage.getItem("account");

  function transformData(data) {
    return data.map((item) => {
      return { value: item.id, label: `${item.code} ${item.name}` };
    });
  }

  useEffect(() => {
    const listData = superAdminListData?.data;

    if (listData) {
      listData?.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      const transformedData = transformData(listData);
      setData(transformedData);
    }
  }, [superAdminListData]);

  function fetchCashData(selected) {
    //console.log("selected", selected);
    // console.log(loggedAccount);

    const payload = {
      user_id: selected,
      acc_type: "super_admin",
      logged_acc_type: String(loggedAccount),
    };

    getCashData(payload, {
      onSuccess: (response) => {
        //  console.log("response", response);
        let updatedCashData = [];

        response.data.forEach((element) => {
          if (element.game != null && element.game.length === 0) {
            const existingIndex = cashData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              updatedCashData.push({
                ...element,
                balance:
                  cashData[existingIndex].balance + element.super_admin_final,
              });
            } else {
              updatedCashData.push({
                ...element,
                balance: element.super_admin_final,
              });
            }
          } else {
            updatedCashData.push({
              ...element,
              balance: 0,
            });
          }
        });

        updatedCashData.reverse();

        for (let index = 0; index < updatedCashData.length; index++) {
          if (index === 0) {
            updatedCashData[index].balance = Math.round(
              updatedCashData[index].super_admin_final
            );
          } else {
            updatedCashData[index].balance =
              Math.round(updatedCashData[index - 1].balance) +
              Math.round(updatedCashData[index].super_admin_final);
          }
        }

        updatedCashData.reverse();

        let totalAmount = 0;
        let debitAmount = 0;
        let creditAmount = 0;

        updatedCashData.forEach((element) => {
          if (element.super_admin_final > 0) {
            debitAmount += element.super_admin_final;
          } else {
            creditAmount += element.super_admin_final;
          }
        }, 0);

        totalAmount = creditAmount + debitAmount;

        setTotalDebit(Math.round(debitAmount));
        setTotalCredit(Math.round(creditAmount));
        setTotalBalance(Math.round(totalAmount));

        setCashData(updatedCashData);

        // console.log("cashData", updatedCashData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    const payload = {
      ...data,
    };

    if (payload.type === "lena") {
      payload.amount = payload.amount * -1;
    }

    const createPayload = {
      acc_type: "super_admin",
      user_id: selectedUser,
      amount: payload.amount,
      match_decision: 1,
      remarks: payload.remark,
    };

    createCashLedger(createPayload, {
      onSuccess: (response) => {
        console.log("response", response);
        form.reset();
        setCashData([]);
        toast.success("Ledger Created Successfully!");
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  return (
    <div className="content-wrapper">
      <PageHeader title="Cash Transaction" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Card cardClass="upperCard" header={`${props.user} Ledger`}>
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <div className="row" id="row1">
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Client</label>
                        <Select
                          options={data.length !== 0 ? data : []}
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={""}
                          isDisabled={false}
                          isLoading={data.length === 0 ? true : false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          name="client"
                          onChange={(e) => {
                            setCashData([]);
                            const selected = e?.value;
                            if (!selected) {
                              return;
                            }
                            setSelectedUser(selected);
                            fetchCashData(selected);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Collection</label>
                        <select class="form-select" required name="collection">
                          <option value="CA1 CASH">CA1 CASH</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Amount</label>
                        <input
                          type="number"
                          name="amount"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Payment Type</label>
                        <select name="type" className="form-select" required>
                          <option value="lena">Receipt - Lena</option>
                          <option value="dena">Payment - Dena</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Remark</label>
                        <input
                          type="text"
                          name="remark"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-12 d-flex">
                      <div className="form-group align-self-end w-100">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Card>

            <Card header="Ledger">
              <div className="table-responsive">
                <table
                  id="datatable"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th style={{ minWidth: "50px", height: "57px" }}>#</th>
                      <th style={{ minWidth: "80px", height: "57px" }}>Date</th>
                      <th style={{ minWidth: "123px", height: "57px" }}>
                        Collection Name
                      </th>
                      <th style={{ minWidth: "58px", height: "57px" }}>
                        Debit
                      </th>
                      <th style={{ minWidth: "59px", height: "57px" }}>
                        Credit
                      </th>
                      <th style={{ minWidth: "69px", height: "57px" }}>
                        Balance
                      </th>
                      <th style={{ minWidth: "110px", height: "57px" }}>
                        Payment Type
                      </th>
                      <th style={{ minWidth: "363px", height: "57px" }}>
                        Remark
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashData.length !== 0 ? (
                      <>
                        <tr className="fw-bold">
                          <td style={{ height: "40px" }}></td>
                          <td></td>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan="1"
                            className={`text-center ${
                              totalBalance > 0 ? "text-primary" : "text-danger"
                            }`}
                          >
                            Total Amount
                          </td>
                          <td
                            className="text-black"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalDebit}
                          </td>
                          <td
                            className="text-black"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalCredit}
                          </td>
                          <td
                            style={{ fontWeight: "bold" }}
                            className={
                              totalBalance > 0 ? "text-primary" : "text-danger"
                            }
                          >
                            {totalBalance}
                          </td>
                          <td></td>
                        </tr>
                        {cashData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ height: "60px" }}>{index + 1}</td>
                              <td>
                                {item.game
                                  ? dateFormatter(item.game.createdAt)
                                  : dateFormatter(item.createdAt)}
                              </td>
                              <td>{item.game ? "CA1 CASH" : "Cash Pay"}</td>
                              <td className="text-black">
                                {item.super_admin_final > 0
                                  ? Math.round(item.super_admin_final)
                                  : 0}
                              </td>
                              <td className="text-black">
                                {item.super_admin_final < 0
                                  ? Math.round(item.super_admin_final)
                                  : 0}
                              </td>
                              <td
                                className={
                                  item.balance > 0 ? "text-black" : "text-black"
                                }
                              >
                                {item.balance}
                              </td>
                              <td></td>
                              <td>
                                {item.game ? item.game.name : item.remarks}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No User Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
