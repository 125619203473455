import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faBan } from "@fortawesome/free-solid-svg-icons";
import useGetAgentMasterList from "../../hooks/AgentMaster/useGetAgentMasterList";
import useGetSuperAgentList from "../../hooks/SuperAgentMaster/useGetSuperAgentList";
import useUpdateAccountStatus from "../../hooks/useUpdateAccountStatus";
import { useQueryClient } from "react-query";
import CreateNewModal from "../../Components/createNewModal";

export default function CasinoDetails() {
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [parentDataList, setParentDataList] = useState([]);
  const accountType = localStorage.getItem("account");

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(currentPage + 1 === Math.ceil(data?.length / itemsPerPage));
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Casino Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Casino Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="form-group">
                    <Link
                      type="button"
                      to="/casino-bet-report"
                      className="btn  bg-indigo mr-2 mt-2"
                    >
                      Casino Bet Details <i className="fa fa-plus-circle"></i>
                    </Link>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table">
                    <div className="row">
                      <div className="parent-container">
                        <div className="col-6">
                          <div
                            className="label-1 dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  setItemsPerPage(e.target.value);
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="input-container col-6 d-flex gap-2 justify-content-end">
                          <label htmlFor="search">Search:</label>
                          <input
                            type="text"
                            id="search"
                            className="form-control form-control-sm w-auto ml-0"
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (value?.length > 0) {
                                searchFilter(value);
                              } else {
                                setData(completeData);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="mobile-d-10">
                            SNo<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-9">
                            Date Time<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-8">
                            Game Type<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-7">
                            Plus Minus<span id="sorted-icon">↑↓</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data?.length !== 0 ? (
                          <>
                            {currentItems?.map((item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <div className="btn-group">
                                        <button
                                          style={{
                                            padding: "3px 7px",
                                            lineHeight: "1.5",
                                          }}
                                          type="button"
                                          className="btn btn-outline-primary bg-primary dropdown-toggle"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span className="sr-only">
                                            Toggle Dropdown
                                          </span>
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          role="menu"
                                        >
                                          <Link
                                            className="dropdown-item"
                                            to={{
                                              pathname:
                                                "/casino-plus-minus-report",
                                            }}
                                            state={item}
                                          >
                                            Show Details
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="mobile-d-10">
                                      <span className="mr-2">1</span>
                                    </td>
                                    <td className="mobile-d-9">{item.name}</td>
                                    <td className="mobile-d-8">
                                      {item.parent}
                                    </td>
                                    <td className="mobile-d-5">{item.limit}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={12} className="text-center p-5">
                                <h6 className="p-5">No records to display</h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {Array.from({
                          length: Math.ceil(data?.length / itemsPerPage),
                        })?.map((_, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => paginate(index + 1)}
                              className={`page-link ${
                                currentPage === index + 1
                                  ? "active-page-link"
                                  : ""
                              }`}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {accountType !== "super_agent" ? (
        <CreateNewModal
          parentName="Super Agent"
          parentDataList={parentDataList}
        />
      ) : null}
    </div>
  );
}
