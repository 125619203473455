import React, { useState, useEffect } from "react";
import "./CreateNewSuperAgent.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";

export default function CreateNewSuperAgent() {
  const navigate = useNavigate();
  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  const { mutate: createAccount, isLoading: isCreatingAccount } =
    useCreateAccount();
  const { mutate: updateLimit, isLoading: isUpdatingLimit } = useUpdateLimit();
  const [superAgentName, setSuperAgentName] = useState("");
  const [superAgentNumber, setSuperAgentNumber] = useState("");
  const [superAgentPassword, setSuperAgentPassword] = useState("");
  const [superAgentReference, setSuperAgentReference] = useState("");
  const [superAgentLimit, setSuperAgentLimit] = useState(0);
  const [superAgentShare, setSuperAgentShare] = useState(0);
  const [superAgentCasinoShare, setSuperAgentCasinoShare] = useState(0);
  const [superAgentMobileShare, setSuperAgentMobileShare] = useState(0);
  const [superAgentCommission, setSuperAgentCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [superAgentMatchCommission, setSuperAgentMatchCommission] = useState(0);
  const [superAgentSessionCommission, setSuperAgentSessionCommission] =
    useState(0);
  const [superAgentCassinoCommission, setSuperAgentCassinoCommission] =
    useState(0);

  useEffect(() => {
    setSuperAgentPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setSuperAgentPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "super_agent",
      name: superAgentName,
      reference: superAgentReference,
      password: String(superAgentPassword),
      parent: parentData.id,
      mobile: superAgentNumber,
      limit: superAgentLimit,
      share: superAgentShare,
      cassino_share: superAgentCasinoShare,
      mobile_share: superAgentMobileShare,
      commission: superAgentCommission,
      match_commission: superAgentMatchCommission,
      session_commission: superAgentSessionCommission,
      cassino_commission: superAgentCassinoCommission,
    };

    createAccount(payload, {
      onSuccess: (data) => {
        console.log(data);
        const superAgentLimitPayload = {
          user_id: data.data.data.id,
          acc_type: "super_agent",
          limit: superAgentLimit,
          coins: superAgentLimit,
          types: "credit",
          description: "Super Agent Limit Update",
        };
        const parentLimitPayload = {
          user_id: parentData.id,
          acc_type: "master",
          limit: parentData.limit - superAgentLimit,
          coins: superAgentLimit,
          types: "debit",
          description: "Master Limit Update",
        };
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            updateLimit(superAgentLimitPayload, {
              onSuccess: () => {
                navigate("/superagents");
                toast.success("Super Agent created successfully!");
              },
              onError: (error) => {
                console.error(error);
              },
            });
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        toast.error("Something went wrong! Please try again later.");
        console.error(error);
      },
    });
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Super Agent </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/superagents">Super Agent</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Super Agent</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentCode"
                          >
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="superAgentCode"
                            name="superAgentCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="superAgentName"
                            name="superAgentName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setSuperAgentName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="superAgentNumber"
                            name="superAgentNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setSuperAgentNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="superAgentReference"
                            name="superAgentReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setSuperAgentReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="superAgentPassword"
                              name="superAgentPassword"
                              className="form-control"
                              required
                              value={superAgentPassword}
                              onChange={(e) => {
                                setSuperAgentPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Limit
                            </label>
                            <input
                              type="number"
                              id="superAgentLimit"
                              name="superAgentLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.limit}
                              required
                              onChange={(e) => {
                                setSuperAgentLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Share
                            </label>
                            <input
                              type="number"
                              id="superAgentShare"
                              name="superAgentShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.share}
                              required
                              onChange={(e) => {
                                setSuperAgentShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Casino Share
                            </label>
                            <input
                              type="number"
                              id="superAgentCasinoShare"
                              name="superAgentCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_share}
                              required
                              onChange={(e) => {
                                setSuperAgentCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="superAgentCommission"
                              name="superAgentCommission"
                              required
                              onChange={(e) => {
                                setSuperAgentCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Match Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentMatchCommission"
                              name="superAgentMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.match_commission}
                              required
                              onChange={(e) => {
                                setSuperAgentMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Session Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentSessionCommission"
                              name="superAgentSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.session_commission}
                              required
                              onChange={(e) => {
                                setSuperAgentSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentCassinoCommission"
                              name="superAgentCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_commission}
                              required
                              onChange={(e) => {
                                setSuperAgentCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/superagents");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Create New Super Agent
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
