import React, { useState, useEffect } from "react";
import "./Casino.css";

import PageHeader from "../../Components/PageHeader";
import useGetAgentMasterList from "../../hooks/AgentMaster/useGetAgentMasterList";
import useGetDataReport from "../../hooks/useGetDataReport";
import { dateTimeFormatter } from "../../utils/dateFormatter";

export default function LiveTeenPati() {
  
  const { mutate: getDataReport } = useGetDataReport();
  const userId = localStorage.getItem("UUID");
  const loggedAccType = localStorage.getItem("account");

  const [data, setData] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [completeLoginData, setCompleteLoginData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(loginData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  
    

    
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loginData.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else if (pageNumber === Math.ceil(loginData?.length / itemsPerPage)) {
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(loginData?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 === Math.ceil(loginData?.length / itemsPerPage)
      );
    }
  };

  return (
    <div className="content-wrapper">
      <PageHeader title={"VirtualTeenPati"} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
          <div class="col-md-6 virtual-teen-pati">

<table class="table table-bordered table-dark" id="casinoOdds" height="200px">

    <thead>
    <tr>
        <th colspan="3" class="text-center">Virtual 3 Patti 20</th>
        <th class="text-center" id="roundId">Round ID: 0</th>
    </tr>

    <tr>
        <th>Player</th>
        <th>KHAI</th>
        <th>LAGAI</th>
        <th>Profit/Loss</th>
    </tr>
    </thead>

    <tbody><tr><td> <a class="btn"> undefined </a> </td><td>  <a href="#" class="btn text-red"> 0.98 </a></td><td>  <a href="#" class="btn text-blue"> 0</a></td><td> <a href="#" class="btn text-bold text-blue"> 0.00 </a> </td></tr><tr><td> <a class="btn"> undefined </a> </td><td>  <a href="#" class="btn text-red"> 0.98 </a></td><td>  <a href="#" class="btn text-blue"> 0</a></td><td> <a href="#" class="btn text-bold text-blue"> 0.00 </a> </td></tr></tbody>


</table>

</div>
            <div className="card ">
              
              
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                      <th>#</th>
                        <th>Client</th>
                        <th>EventId</th>
                        <th>Player</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loginData && loginData?.length !== 0 ? (
                        <>
                          {currentItems?.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.user_agent.code}</td>
                                  <td>{item.user_agent.name}</td>
                                  <td>{item.old_balance}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.types}</td>
                                  <td>{dateTimeFormatter(item.createdAt)}</td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr className="bg-white">
                            <td colSpan="7">
                              <h6 className="text-center">
                                There are no records to display
                              </h6>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeLoginData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {pageNumbers?.map((number, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => {
                                if (number !== "...") {
                                  paginate(number);
                                }
                              }}
                              className={`page-link ${
                                currentPage === number ? "active-page-link" : ""
                              } ${number === "..." ? "disabled" : ""}`}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
