import React from "react";
// import Sidebar from "../../components/Sidebar";
// import Sidebar from "../../Components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { decryptData } from "../../utils/decryptData";
import useGetAdminList from "../../hooks/Admin/useGetAdminList";
import useUpdateAccountStatus from "../../hooks/useUpdateAccountStatus";
import { useQueryClient } from "react-query";

export default function AdminList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: adminListData } = useGetAdminList();
  const { mutate: updateAccountStatus } = useUpdateAccountStatus();

  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    // AdminList();
    console.log(adminListData);
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const AdminAllActive = async () => {
    useEffect(() => {
      const listData = adminListData?.data;

      if (listData) {
        listData.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });
        setData(listData);
        setCompleteData(listData);
        console.log(listData);
      }
    }, [adminListData]);

    function searchFilter(value) {
      const filteredData = data.filter((item) => {
        return (
          item.code.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase())
        );
      });

      setData(filteredData);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const [isFirstPage, setIsFirstPage] = useState(true);
    const [isLastPage, setIsLastPage] = useState(false);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevious = () => {
      if (currentPage > 1) {
        paginate(currentPage - 1);
        setIsFirstPage(currentPage - 1 === 1);
        setIsLastPage(false);
      }
    };

    const handleNext = () => {
      if (currentPage < Math.ceil(data.length / itemsPerPage)) {
        paginate(currentPage + 1);
        setIsFirstPage(false);
        setIsLastPage(
          currentPage + 1 === Math.ceil(data.length / itemsPerPage)
        );
      }
    };

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleUserSelect = (e, userId) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        setSelectedUsers([...selectedUsers, userId]);
      } else {
        setSelectedUsers(selectedUsers.filter((id) => id !== userId));
      }
    };

    const handleSelectAll = (e) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        setSelectedUsers(data.map((item) => item.id));
      } else {
        setSelectedUsers([]);
      }

      setSelectAll(isChecked);
    };

    const masterAllActive = () => {
      const acc_type = "master";
      const status = true;

      selectedUsers.forEach((id) => {
        const payload = { acc_type, id, status };

        updateAccountStatus(payload, {
          onSuccess: () => {
            setSelectedUsers([]);
            toast.success("Clients activated successfully");
            queryClient.invalidateQueries("get-master-list");
          },
          onError: () => {
            toast.error("Failed to activate client");
          },
        });
      });
    };

    const masterAllDeactive = () => {
      const acc_type = "master";
      const status = false;

      selectedUsers.forEach((id) => {
        const payload = { acc_type, id, status };

        updateAccountStatus(payload, {
          onSuccess: () => {
            setSelectedUsers([]);
            toast.success("Clients deactivated successfully");
            queryClient.invalidateQueries("get-master-list");
          },
          onError: () => {
            toast.error("Failed to deactivate client");
          },
        });
      });
    };

    const createNewAgent = () => {
      navigate("/agent/create");
    };

    return (
      <>
        {/* <Sidebar /> */}
        <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1> Admin</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard" href="#">
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="#">Admin</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-header">
                      <div className="form-group">
                        <button
                          type="button"
                          class="btn btn-primary mr-2 mt-2"
                          onClick={createNewAgent}
                        >
                          New{" "}
                          <i className="fa fa-plus-circle">
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </i>
                        </button>
                        <button
                          className={`btn btn-success mr-2 mt-2 ${
                            selectedUsers.length === 0 ? "disabled" : ""
                          }`}
                          id="allActive"
                          onClick={masterAllActive}
                        >
                          All Active
                        </button>

                        <button
                          className={`btn btn-danger mr-2 mt-2 ${
                            selectedUsers.length === 0 ? "disabled" : ""
                          }`}
                          id="allInActive"
                          onClick={masterAllActive}
                        >
                          All DeActivate{" "}
                          <i className="fa fa-ban">
                            <FontAwesomeIcon icon={faBan} />
                          </i>
                        </button>

                        <Link
                          to="/masters/limitUpdate"
                          className="btn btn-primary mr-2 mt-2"
                        >
                          Limit Update
                        </Link>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="table">
                        <div className="row">
                          <div className="parent-container">
                            <div className="col-6">
                              <div
                                className="label-1 dataTables_length"
                                id="example1_length"
                              >
                                <label>
                                  Show
                                  <select
                                    className="custom-select"
                                    onChange={(e) => {
                                      setItemsPerPage(e.target.value);
                                    }}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="input-container col-6 d-flex gap-2 justify-content-end">
                              <label htmlFor="search">Search:</label>
                              <input
                                type="text"
                                className="form-control form-control-sm w-50 ml-0"
                                onChange={(e) => {
                                  const value = e.target.value.trim();
                                  if (value.length > 0) {
                                    searchFilter(value);
                                  } else {
                                    setData(completeData);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <table
                          id="datatable"
                          className="table table-bordered table-striped dataTable no-footer dtr-inline"
                          role="grid"
                          aria-describedby="example1_info"
                        >
                          <thead>
                            <tr>
                              <th class="dynamicValue"></th>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                  style={{ cursor: "pointer" }}
                                />
                              </th>
                              <th width="50px">#</th>
                              <th className="pd12">Code</th>
                              <th className="pd12">Name</th>
                              <th class="mobile-d-6">Password</th>
                              <th class="mobile-d-5">Contact</th>
                              {/* <th>D.O.J. </th> */}
                              <th class="mobile-d-4">Share</th>
                              <th class="mobile-d-3">Com</th>
                              <th class="mobile-d-2">Limit</th>
                              <th class="mobile-d-1">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length !== 0 ? (
                              <>
                                {currentItems.map((item, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="dtr-control">
                                          <input type="checkbox" name="" />
                                        </td>
                                        <td>
                                          <span className="mr-3">
                                            {index + 1}
                                          </span>
                                          <div className="btn-group">
                                            <button
                                              style={{ padding: "2px 8px" }}
                                              type="button"
                                              className="btn btn-outline-primary dropdown-toggle"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="sr-only">
                                                Toggle Dropdown
                                              </span>
                                            </button>
                                            <div
                                              className="dropdown-menu"
                                              role="menu"
                                              style={{}}
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to={{
                                                  pathname: "/admin/update",
                                                }}
                                                state={{
                                                  item: item,
                                                }}
                                              >
                                                Edit
                                              </Link>
                                            </div>
                                          </div>
                                          <span className="ml-3">
                                            {item.matkaName}
                                          </span>
                                        </td>
                                        <td>{item.id}</td>
                                        <td>{item.uid}</td>
                                        <td>{item.name}</td>
                                        <td>{item.password}</td>
                                        <td>{item.contact_no}</td>
                                        {/* <td>{item.createdAt.slice(0, 10)}</td> */}
                                        <td>{item.share}</td>
                                        <td>{item.cassinoCommission}</td>
                                        <td>{item.limit}</td>
                                        <td>
                                          {item.status ? (
                                            <button
                                              className="btn btn-sm btn-success"
                                              style={{
                                                cursor: "default",
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Active
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-sm btn-danger"
                                              style={{
                                                cursor: "default",
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Inactive
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={12} className="text-center p-5">
                                    <h6 className="p-5">
                                      No records to display
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="example1_info">
                          Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                          {completeData.length} entries
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="example1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className={`paginate_button page-item previous ${
                                isFirstPage ? "disabled" : ""
                              }`}
                              id="example1_previous"
                            >
                              <a
                                href="#"
                                tabIndex="0"
                                className="page-link"
                                onClick={handlePrevious}
                              >
                                Previous
                              </a>
                            </li>
                            {Array.from({
                              length: Math.ceil(data.length / itemsPerPage),
                            }).map((_, index) => (
                              <li key={index} className="page-item">
                                <a
                                  href="#"
                                  onClick={() => paginate(index + 1)}
                                  className={`page-link ${
                                    currentPage === index + 1
                                      ? "active-page-link"
                                      : ""
                                  }`}
                                >
                                  {index + 1}
                                </a>
                              </li>
                            ))}
                            <li
                              className={`paginate_button page-item next ${
                                isLastPage ? "disabled" : ""
                              }`}
                              id="example1_next"
                            >
                              <a
                                href="#"
                                tabIndex="0"
                                className="page-link"
                                onClick={handleNext}
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <h5 class="modal-title ml-3" id="exampleModalLabel">
              Password Reset
            </h5>
          </DialogTitle>
          <DialogContent>
            <form>
              <div class="modal-body">
                <input
                  type="hidden"
                  name="urlr"
                  value="Admin_controller/sub_admin_details"
                />
                <input type="hidden" name="ids" value="405" />
                <input
                  type="text"
                  name="password"
                  class=" form-control input-password"
                  required=""
                />
                <button
                  type="button"
                  class="btn btn-success2"
                  name="show"
                  value="generate"
                >
                  Generate Password
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={handleClose}
                  data-dismiss="modal"
                  class="btn btn-cancel mt-0"
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={handleClose}
                  class="btn btn-primary-bg"
                >
                  Confirm
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );
  };
}
