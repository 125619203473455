import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Casino.css";

export default function CasinoPlusMinusReport() {
  const navigate = useNavigate();

  const CasinoPlusMinusTable = () => {
    navigate("/casino-plus-minus-table");
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Casino Plus Minus Report :2024-02-12</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <a>Casino Plus Minus Select</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <input
                    type="hidden"
                    name="market_id"
                    defaultValue="1.202052720"
                  />
                  <input type="hidden" name="page_name" defaultValue="match" />
                  <input type="hidden" name="type" defaultValue="b" />
                  <input type="hidden" name="type" defaultValue="b" />
                  <div className="card-header text-center">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <h5 className="h5-color">Agent OF : 2024-02-12</h5>
                        </div>
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                              console.log("decision game");
                              CasinoPlusMinusTable();
                            }}
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-8">
                        <table
                          align="left"
                          id="sessionsTable"
                          className="table table-striped  table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th width="15%">
                                <div align="center">
                                  <input
                                    type="checkbox"
                                    id="allSessions"
                                    defaultValue={1}
                                    defaultChecked
                                  />
                                </div>
                              </th>
                              <th>Super Code</th>
                              <th>Super Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div align="center">
                                  <input
                                    defaultChecked
                                    type="checkbox"
                                    className="custom-checkbox"
                                    defaultValue="97d4955e8c8894b4803485002e8aa5ca69f9f515"
                                    id="97d4955e8c8894b4803485002e8aa5ca69f9f515"
                                    name="session_array[]"
                                  />
                                </div>
                              </td>
                              <td>SA12140 </td>
                              <td>hemant</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        <table
                          align="left"
                          id="clientTable"
                          className="table table-striped  table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th width="15%">
                                <div align="center">
                                  <input
                                    onclick="click()"
                                    type="checkbox"
                                    id="allClient"
                                    defaultValue={1}
                                    defaultChecked
                                  />
                                </div>
                              </th>
                              <th>Game Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div align="center">
                                  <input
                                    defaultChecked
                                    type="checkbox"
                                    className="custom-checkbox checkbox"
                                    defaultValue={654}
                                    id="654_user_id"
                                    name="downline_array[]"
                                  />
                                </div>
                              </td>
                              <td>Live Teen Patti 20</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
