import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../Components/PageHeader";
import useGetBetDetails from "../hooks/Games/useGetBetDetails";

export default function BetDetails () {

  const navigate = useNavigate();
  const { data: betDetails} = useGetBetDetails();

  const [data, setData] = useState([]);
  const accountType = localStorage.getItem("account");

  useEffect(() => {
    if (betDetails) {
      setData(betDetails?.data?.data);
      console.log(betDetails?.data?.data);
    }
  }, [betDetails]);
 
    return (
      <>
        <div className="content-wrapper">
          <PageHeader title="Match & Session Bet Details MatchCode" />

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card */}
                  <div className="card">
                    <div className="card-header ">
                      <div className="form-row col-md-9">
                        <div
                          className="form-group col-md-4"
                          data-select2-id={4}
                        >
                          <label htmlFor="name">Client</label>
                          <select
                            className="form-control select2 select2-hidden-accessible"
                            required
                            onchange="select()"
                            id="client_id"
                            placeholder="Select Client"
                            name="client"
                            data-select2-id="name"
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            <option value={0} data-select2-id={2}>
                              Select Client...
                            </option>
                            
                          </select>
                          
                        </div>
                        
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div>
                        <div className="form-row w-100">
                          <div className="col-md-6">
                            <table className="table table-responsive table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    className="text-center bg-secondary"
                                    colSpan={9}
                                  >
                                    MATCH BETS
                                  </th>
                                </tr>
                                <tr>
                                  <th>#</th>
                                  <th>Rate</th>
                                  <th>Mode</th>
                                  <th>Team</th>
                                  <th>Amount</th>
                                  <th>Manchester Originals Women</th>
                                  <th>Welsh Fire Women</th>{" "}
                                  <th>Date &amp; Time</th>
                                </tr>
                              </thead>
                              <tbody id="match_bets">
                                <tr className>
                                  <td>1</td>
                                  <td>0.32</td>
                                  <td>LAGAI</td>
                                  <td>MANCHESTER ORIGINALS WOMEN</td>
                                  <td>500</td>
                                  <td>160</td>
                                  <td>-500</td>
                                   <td>16-08-2022 21:25:59</td>
                                </tr>
                              </tbody>
                              <tbody id="match_total">
                                <tr>
                                  <th className="text-center" colSpan={2}>
                                    {" "}
                                  </th>
                                  <th className="text-center">Total </th>
                                  <th className="text-red" />
                                  <th className="text-red">
                                    <span style={{ color: "#007bff" }}>
                                      500
                                    </span>
                                  </th>
                                  <th className="text-red">
                                    <span style={{ color: "#007bff" }}>
                                      160
                                    </span>
                                  </th>
                                  <th className="text-red">
                                    <span style={{ color: "red" }}>-500</span>
                                  </th>
                                  <th />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6">
                            <table className="table table-responsive table-striped table-bordered">
                              <thead>
                                <tr> 
                                  <th
                                    className="text-center bg-secondary"
                                    colSpan={8}
                                  >
                                    SESSION BETS
                                  </th>
                                </tr>
                                <tr>
                                  <th>#</th>
                                  <th>Session</th>
                                  <th>Rate</th>
                                  <th>Run</th>
                                  <th>Mode</th>
                                  <th>Amount</th>
                                  <th>Dec</th>
                                  <th>Date &amp; Time</th>
                                </tr>
                              </thead>
                              <tbody id="session_bet">
                                
                                
                                
                                <tr className>
                                  <td>16</td>
                                  <td>1 TO 25 BALLS RUNS MO W</td>
                                  <td>1.00</td>
                                  <td>30</td>
                                  <td>N</td>
                                  <td>220 </td>
                                  <td>29</td>
                                  <td>16-08-2022 19:31:48</td>
                                </tr>
                              </tbody>
                              <tfoot id="session_total">
                                <tr>
                                  <th colSpan={5} className="text-center">
                                    Session Plus
                                  </th>
                                  <th colSpan={3} className="text-blue">
                                    <span style={{ color: "#007bff" }}>70</span>
                                  </th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </>
    );
  }
