/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useSocketBetguru from "../../hooks/Sockets/useSocketBetguru";
import useSocketIceexch from "../../hooks/Sockets/useSocketIceexch";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetSessionSetting from "../../hooks/Games/Inplay/useGetSessionSetting";
import useGetGameDetailsPaid from "../../hooks/Games/Inplay/useGetGameDetailsPaid";
import useGetAppleExch from "../../hooks/Games/Inplay/useGetAppleExch";
import useGetScoreBoard5 from "../../hooks/Games/Inplay/useGetScoreBoard5";
import useGetBettingAdmin from "../../hooks/Games/Inplay/useGetBettingAdmin";
import useGetSessionByName from "../../hooks/Games/Inplay/useGetSessionByName";
import { match as matchDummy, match4 } from "../../cms/gameData";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import "./InplayControlGame.css";

export default function InplayMatchSessionPosition() {
  const location = useLocation();
  let gameDetails = [];
  let gameId = "";
  let gameCode = "";
  let userShare = 0;
  let session_automatic = true;
  let scoreCode = "";
  const userData = JSON.parse(localStorage.getItem("DATA"));
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  if (location.state) {
    gameDetails = location.state;
    gameCode = location.state.code;
    gameId = location.state.id;
    userShare = userData.share;
    session_automatic = location.state.session_automatic;
    scoreCode = location.state.score_code;
  }

  const { mutate: getBetsData } = useGetBetsData();
  const { mutate: getSessionSetting } = useGetSessionSetting();
  const { mutate: getGameDetailsPaid } = useGetGameDetailsPaid();
  const { mutate: getAppleExch } = useGetAppleExch();
  const { mutate: getScoreBoard5 } = useGetScoreBoard5();
  const { mutate: getBettingAdmin } = useGetBettingAdmin();
  const { mutate: getSessionByName } = useGetSessionByName();

  const [scoreBoardType, setScoreBoardType] = useState("");

  const [matchBets, setMatchBets] = useState([]);
  const [matchSession, setMatchSession] = useState([]);
  const [gameSessionSetting, setGameSessionSetting] = useState([]);
  const [matchSessionSetting, setMatchSessionSetting] = useState(null);
  const [dataResult, setDataResult] = useState(null);
  const [totalMatch1, setTotalMatch1] = useState(0);
  const [totalMatch2, setTotalMatch2] = useState(0);
  const [totalMatch3, setTotalMatch3] = useState(0);
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [team3, setTeam3] = useState("");
  const [team1Yes, setTeam1Yes] = useState("");
  const [team1No, setTeam1No] = useState("");
  const [team2Yes, setTeam2Yes] = useState("");
  const [team2No, setTeam2No] = useState("");
  const [team3Yes, setTeam3Yes] = useState("");
  const [team3No, setTeam3No] = useState("");
  const [gameTitle, setGameTitle] = useState("");
  const [recentBall, setRecentBall] = useState("");
  const [recentBallData, setRecentBallData] = useState([]);
  const [teamName1, setTeamName1] = useState("");
  const [teamName2, setTeamName2] = useState("");
  const [teamName3, setTeamName3] = useState("");
  const [team1Status, setTeam1Status] = useState("");
  const [team2Status, setTeam2Status] = useState("");
  const [lastWicket, setLastWicket] = useState("");
  const [player1, setPlayer1] = useState("");
  const [player2, setPlayer2] = useState("");
  const [team1Position, setTeam1Position] = useState(0);
  const [team2Position, setTeam2Position] = useState(0);
  const [team3Position, setTeam3Position] = useState(0);
  const [showMatchBets, setShowMatchBets] = useState(false);
  const [showSessionBets, setShowSessionBets] = useState(false);
  const [lastBetPlayed, setLastBetPlayed] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [matchId1, setMatchId1] = useState(0);
  const [matchId2, setMatchId2] = useState(0);

  const betguruData = useSocketBetguru(matchId1);
  const iceexchData = useSocketIceexch(matchId2);

  let fancyOdds = [];
  const [fancyOddsData, setFancyOddsData] = useState([]);

  const showUserPlayed = (matchId) => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(payload, {
      onSuccess: (data) => {
        setMatchBets(data.data);

        let total1 = 0;
        let total2 = 0;
        let total3 = 0;

        data.data.forEach((el) => {
          total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
          total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
          total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
        });

        setTotalMatch1(Math.round(total1));
        setTotalMatch2(Math.round(total2));
        setTotalMatch3(Math.round(total3));
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const showSessionPlay = (sessionName) => {
    const payload = {
      match_id: gameId,
      session: sessionName,
      acc_type: accountType,
      user_id: userId,
    };

    getSessionByName(payload, {
      onSuccess: (data) => {
        setMatchSession(data.data);

        if (data.data.length > 0) {
          setLastBetPlayed(data.data[0].runs);
          const runs = data.data.reduce((a, b) => ({
            amount: a.amount + b.amount,
          })).amount;

          setTotalAmount(runs - (runs * Number(userShare)) / 100);
        } else {
          setLastBetPlayed(0);
          setTotalAmount(0);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

    showUserPlayed(gameId);
  };

  const handleGetSessionSetting = (gameId) => {
    getSessionSetting(gameId, {
      onSuccess: (data) => {
        setGameSessionSetting(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const getGameDetails = (data) => {
    setTeam1(gameDetails.team1);
    setTeam2(gameDetails.team2);
    setTeam3("DRAW");

    if (data.length > 0) {
      setTeamName1(data[0].markets[0].runners[0].runnerName);
      setTeamName2(data[0].markets[0].runners[1].runnerName);
      if (gameDetails.match_type !== "Test") {
        setTeam1Yes(
          data[0].markets[0].runners[0].backOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + ""
        );
        setTeam2Yes(
          data[0].markets[0].runners[1].backOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + ""
        );
        setTeam1No(
          data[0].markets[0].runners[0].layOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].layOdds + ""
        );
        setTeam2No(
          data[0].markets[0].runners[1].layOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].layOdds + ""
        );
      }
    }

    // if (gameDetails.match_type === "Test") {
    //   setTeam3Yes(
    //     data[0].markets[0].runners[2].backOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].backOdds
    //   );
    //   setTeam3No(
    //     data[0].markets[0].runners[2].layOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].layOdds
    //   );
    // }

    fancyOdds = [];
    let fancyOddsIndex = data.length < 3 ? 1 : 2;

    if (gameDetails.match_type !== "Test") {
      if (session_automatic) {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });
        }

        gameSessionSetting.forEach((ele) => {
          if (
            fancyOdds.some(
              (elm) => elm.marketType == ele.session_name && ele.allow
            )
          ) {
            const index = fancyOdds.findIndex(
              (elen) => elen.marketType === ele.session_name
            );
            fancyOdds[index].runners[0].rnrBallRunning = true;
          }
        });
      } else {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    } else {
      if (session_automatic) {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });

          gameSessionSetting.forEach((ele) => {
            if (
              fancyOdds.some(
                (elm) => elm.marketType === ele.session_name && ele.allow
              )
            ) {
              const index = fancyOdds.findIndex(
                (elen) => elen.marketType === ele.session_name
              );
              fancyOdds[index].runners[0].rnrBallRunning = true;
            }
          });
        }
      } else {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails3 = (data) => {
    setTeam1(gameDetails.team1);
    setTeam2(gameDetails.team2);
    setTeam3("DRAW");

    setTeam1Yes(
      data.market[0].events[0].BackPrice1 === "0.00" ||
        data.market[0].events[0].BackPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[0].BackPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[0].BackPrice1.split(".")[1])
    );

    setTeam1No(
      data.market[0].events[0].LayPrice1 === "0.00" ||
        data.market[0].events[0].LayPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[0].LayPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[0].LayPrice1.split(".")[1])
    );

    setTeam2Yes(
      data.market[0].events[1].BackPrice1 === "0.00" ||
        data.market[0].events[1].BackPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[1].BackPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[1].BackPrice1.split(".")[1])
    );

    setTeam2No(
      data.market[0].events[1].LayPrice1 === "0.00" ||
        data.market[0].events[1].LayPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[1].LayPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[1].LayPrice1.split(".")[1])
    );

    if (gameDetails.match_type === "Test") {
      setTeam3Yes(
        data.market[0].events[2].BackPrice1 === "0.00" ||
          data.market[0].events[2].BackPrice1 === "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[2].BackPrice1.split(".").length === 1
                ? "0"
                : data.market[0].events[2].BackPrice1.split(".")[1])
      );

      setTeam3No(
        data.market[0].events[2].LayPrice1 === "0.00" ||
          data.market[0].events[2].LayPrice1 === "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[2].LayPrice1.split(".").length === 1
                ? "0"
                : data.market[0].events[2].LayPrice1.split(".")[1])
      );
    }

    fancyOdds = [];

    if (session_automatic) {
      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) === 0,
          },
        ];
        fancyOdds.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      gameSessionSetting.forEach((ele) => {
        if (
          fancyOdds.some(
            (elm) => elm.marketType === ele.session_name && ele.allow
          )
        ) {
          const index = fancyOdds.findIndex(
            (elen) => elen.marketType === ele.session_name
          );
          fancyOdds[index].runners[0].rnrBallRunning = true;
        }
      });
    } else {
      fancyOdds = [];
      let allSessions = [];

      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) === 0,
          },
        ];

        allSessions.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      allSessions.forEach((el) => {
        if (el.runners !== undefined) {
          if (
            gameSessionSetting.some(
              (elem) =>
                elem.session_name === el.marketType && elem.allow === true
            )
          ) {
            fancyOdds.push(el);
          }
        }
      });
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails2 = (data) => {
    if (Object.keys(data).length > 0 && Object.keys(data).length !== 4) {
      let match = data.matchOdds;
      let session = data.sessionOdds;
      let score = data.score;

      if (match.length > 0) {
        setTeam1Yes(match[0].krate);
        setTeam1No(match[0].lrate);
        setTeam2Yes(match[1].krate);
        setTeam2No(match[1].lrate);

        if (gameDetails.match_type === "Test") {
          setTeam3Yes(match[2].krate);
          setTeam3No(match[2].lrate);
        }
      }

      setGameTitle(score?.msg);
      setRecentBall(score?.recentBall);
      let arr = score?.recentBall.split(" ");
      let arrFixed = new Array(6).fill(null).map((_, i) => arr[i] || "-");
      setRecentBallData(arrFixed);
      setTeamName1(score?.team1);
      setTeamName2(score?.team2);
      setTeamName3("DRAW");
      setTeam1Status(score?.score1);
      setTeam2Status(score?.score2);
      setLastWicket(score?.bwl);
      setPlayer1(score?.player1);
      setPlayer2(score?.player2);

      let fancyOdds = [];

      if (session.length > 0) {
        session.forEach((el) => {
          let newSession = {
            id: el.id,
            marketType: el.name,
            runners: [
              {
                backRuns: el.yrun,
                backOdds: el.yrate * 100,
                layRuns: el.nrun,
                layOdds: el.nrate * 100,
                rnrBallRunning: el.yrate === 0 ? true : false,
                id: el.id,
              },
            ],
          };

          fancyOdds.push(newSession);
        });
      }

      setFancyOddsData(fancyOdds);
    }
  };

  const handleGetGameDetailsPaid = (gameCode) => {
    setMatchId2(gameCode);
  };

  const handleGetAppleExch = (gameCode) => {
    setMatchId1(gameCode);
  };

  const getMatchPosition = () => {
    const payload = {
      user_id: userId,
      match_id: gameId,
      acc_type: accountType,
    };

    getBettingAdmin(payload, {
      onSuccess: (data) => {
        let below_share = 0;
        let logged_in_share = 0;

        data.data.data.forEach((el) => {
          if (accountType === "admin") {
            logged_in_share = 100;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.user_super_admin.share;
          }
          if (accountType === "super_admin") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.user_super_admin.share;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.share;
          }
          if (accountType === "sub_admin") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.share;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master.share;
          }
          if (accountType === "master") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master.share;
            below_share = el.user_accounts.user_agent.user_super_agent.share;
          }
          if (accountType === "super_agent") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.share;
            below_share = el.user_accounts.user_agent.share;
          }
          if (accountType === "agent") {
            logged_in_share = el.user_accounts.user_agent.share;
            below_share = el.user_accounts.user_agent.share;
          }

          let share = logged_in_share - below_share;

          setTeam1Position(
            ((el.teamA_bet_amount + el.teamA_lay_amount) * share) / 100
          );
          setTeam2Position(
            ((el.teamB_bet_amount + el.teamB_lay_amount) * share) / 100
          );
          setTeam3Position(
            ((el.draw_bet_amount + el.draw_lay_amount) * share) / 100
          );
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const betConverter = (value) => {
    if (gameDetails.api === "2") {
      return value !== "0" && value !== "0.0" && value !== "0.00"
        ? parseFloat(value).toFixed(2)
        : "0";
    } else {
      return value === "0" || value === ""
        ? "0.00"
        : (Number(value) / 100).toFixed(2);
    }
  };

  useEffect(() => {
    if (gameId) {
      showUserPlayed(gameId);
      handleGetSessionSetting(gameId);

      const intervalId = setInterval(() => {
        handleGetSessionSetting(gameId);
      }, 6000);

      setMatchSessionSetting(intervalId);

      getMatchPosition();

      if (scoreCode !== undefined || scoreCode !== "" || scoreCode !== null) {
        if (scoreCode.startsWith("1")) {
          setScoreBoardType("External");
        } else {
          setScoreBoardType("Betguru");
        }
      }

      if (gameDetails.api === "1") {
        if (gameCode !== "0") {
          handleGetGameDetailsPaid(gameCode);
        } else {
          getGameDetails(matchDummy.data);
        }
      } else {
        if (gameCode !== "0") {
          handleGetAppleExch(gameCode);
        } else {
          getGameDetails3(match4);
        }
      }

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  useEffect(() => {
    if (iceexchData) {
      getGameDetails(iceexchData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iceexchData]);

  useEffect(() => {
    if (betguruData) {
      getGameDetails2(betguruData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betguruData]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Session Position</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Session Position</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              <div className="card-header card-header-top-line">inplay</div>
              <div className="card-body">
                {scoreBoardType === "Betguru" ? (
                  <iframe
                    title="demo"
                    width="100%"
                    src={`https://score.zioplay.live/score.html?MatchID=${scoreCode}`}
                    style={{ height: "160px", marginTop: "-16px" }}
                  ></iframe>
                ) : (
                  <iframe
                    title="demo"
                    width="100%"
                    src={`https://winx777.com/score/?market_id=${scoreCode}`}
                    style={{ height: "180px", marginTop: "-16px" }}
                  ></iframe>
                )}

                <div className="row align-items-start pt-3">
                  <div className="col-md-6 px-1">
                    <table className="table table-bordered ">
                      <thead>
                        <tr>
                          <th>TEAM</th>
                          <th
                            className="text-primary"
                            style={{ width: "60px" }}
                          >
                            LAGAI
                          </th>
                          <th
                            className="text-danger"
                            style={{ width: "60px", height: "48px" }}
                          >
                            KHAI
                          </th>
                          <th>
                            <span
                              className="text-primary"
                              style={{ height: "48px" }}
                            >
                              PLUS
                            </span>{" "}
                            / <span className="text-danger">MINUS</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={{ height: "60px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            getMatchPosition();
                            setShowMatchBets(true);
                            setShowSessionBets(false);
                            showUserPlayed(gameId);
                          }}
                        >
                          <td
                            className="text-uppercase"
                            style={{ verticalAlign: "middle" }}
                          >
                            {teamName1}
                          </td>
                          <td
                            className="text-primary text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team1Yes)}
                          </td>
                          <td
                            className="text-danger text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team1No)}
                          </td>
                          <td
                            className={`${
                              team1Position > 0 ? "text-danger" : "text-primary"
                            }  text-bold text-md`}
                            style={{ verticalAlign: "middle" }}
                          >
                            {team1Position}
                          </td>
                        </tr>
                        <tr
                          style={{ height: "48px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            getMatchPosition();
                            setShowMatchBets(true);
                            setShowSessionBets(false);
                            showUserPlayed(gameId);
                          }}
                        >
                          <td
                            className="text-uppercase"
                            style={{ verticalAlign: "middle" }}
                          >
                            {teamName2}
                          </td>
                          <td
                            className="text-primary text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team2Yes)}
                          </td>
                          <td
                            className="text-danger text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team2No)}
                          </td>
                          <td
                            className={`${
                              team2Position > 0 ? "text-danger" : "text-primary"
                            }  text-bold text-md`}
                            style={{ verticalAlign: "middle" }}
                          >
                            {team2Position}
                          </td>
                        </tr>
                        {gameDetails.match_type === "Test" && (
                          <tr
                            style={{ height: "48px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              getMatchPosition();
                              setShowMatchBets(true);
                              setShowSessionBets(false);
                              showUserPlayed(gameId);
                            }}
                          >
                            <td
                              className="text-uppercase"
                              style={{ verticalAlign: "middle" }}
                            >
                              {teamName3}
                            </td>
                            <td
                              className="text-primary text-bold text-md"
                              style={{ verticalAlign: "middle" }}
                            >
                              {betConverter(team3Yes)}
                            </td>
                            <td
                              className="text-danger text-bold text-md"
                              style={{ verticalAlign: "middle" }}
                            >
                              {betConverter(team3No)}
                            </td>
                            <td
                              className={`${
                                team3Position > 0
                                  ? "text-danger"
                                  : "text-primary"
                              }  text-bold text-md`}
                              style={{ verticalAlign: "middle" }}
                            >
                              {team3Position}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 px-1">
                    <table className="table table-bordered ">
                      {/* session Heading code */}
                      <thead>
                        <tr>
                          <th>SESSION</th>
                          <th
                            className="text-danger"
                            style={{ width: "100px", height: "48px" }}
                          >
                            NOT
                          </th>
                          <th
                            className="text-primary"
                            style={{ width: "100px" }}
                          >
                            YES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fancyOddsData?.length !== 0 ? (
                          fancyOddsData?.map((odd, index) => (
                            <tr key={index} style={{ cursor: "pointer" }}>
                              <td
                                style={{ verticalAlign: "middle" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowMatchBets(false);
                                  setShowSessionBets(true);
                                  showSessionPlay(odd.marketType);
                                }}
                              >
                                {odd.marketType}
                              </td>
                              <td className="text-danger text-bold text-md">
                                {odd.runners[0].rnrBallRunning ? (
                                  <>
                                    <div className="pb-2">0</div>
                                    0.0
                                  </>
                                ) : (
                                  <>
                                    <div className="pb-2">
                                      {odd.runners[0].layRuns}
                                    </div>
                                    {odd.runners[0].layOdds / 100}
                                  </>
                                )}
                              </td>
                              <td className="text-primary text-bold text-md">
                                {odd.runners[0].rnrBallRunning ? (
                                  <>
                                    <div className="pb-2">0</div>
                                    0.0
                                  </>
                                ) : (
                                  <>
                                    <div className="pb-2">
                                      {odd.runners[0].backRuns}
                                    </div>
                                    {odd.runners[0].backOdds / 100}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No Session Available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {showSessionBets && (
                  <div className="row align-items-start">
                    <div className="col-lg-10">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-striped"
                          id="datatable"
                        >
                          <thead>
                            <tr>
                              <th style={{ padding: ".85rem" }}>#</th>
                              <th style={{ padding: ".85rem" }}>Session</th>
                              <th style={{ padding: ".85rem" }}>Runs</th>
                              <th style={{ padding: ".85rem" }}>Rate</th>
                              <th style={{ padding: ".85rem" }}>Mode</th>
                              <th style={{ padding: ".85rem" }}>Amount</th>
                              <th style={{ padding: ".85rem" }}>Client</th>
                              <th style={{ padding: ".85rem" }}>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchSession?.length > 0 ? (
                              matchSession.map((session, index) => (
                                <tr key={index}>
                                  <td style={{ padding: ".85rem" }}>
                                    {index + 1}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.session}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.runs}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.rate}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.mode}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.amount}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    ({session.user_account.code}){" "}
                                    {session.user_account.name}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {dateTimeFormatter(session.createdAt)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8">No Session found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <table
                        className="table table-bordered table-striped"
                        id="datatable"
                      >
                        <thead>
                          <tr>
                            <th style={{ padding: ".85rem" }}>Runs</th>
                            <th style={{ padding: ".85rem" }}>Amount</th>
                          </tr>
                        </thead>
                        {matchSession?.length > 0 ? (
                          <tbody>
                            <tr>
                              <td style={{ padding: ".85rem" }}>
                                {lastBetPlayed - 1}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {totalAmount}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: ".85rem" }}>
                                {lastBetPlayed}
                              </td>
                              <td
                                className="text-danger text-md"
                                style={{ fontWeight: 700, padding: ".85rem" }}
                              >
                                {totalAmount}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: ".85rem" }}>
                                {lastBetPlayed + 1}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {totalAmount}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="2">No data found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}

                {showMatchBets && (
                  <div className="row align-items-start">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-striped"
                          id="datatable"
                        >
                          <thead>
                            <tr>
                              <th style={{ padding: ".85rem" }}>Client</th>

                              <th style={{ padding: ".85rem" }}>Rate</th>
                              <th style={{ padding: ".85rem" }}>Amount</th>
                              <th style={{ padding: ".85rem" }}>Mode</th>
                              <th style={{ padding: ".85rem" }}>Team</th>
                              <th style={{ padding: ".85rem" }}>{teamName1}</th>
                              <th style={{ padding: ".85rem" }}>{teamName2}</th>
                              <th style={{ padding: ".85rem" }}>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchBets?.length > 0 ? (
                              matchBets.map((bets, index) => (
                                <tr key={index}>
                                  <td style={{ padding: ".85rem" }}>
                                    {bets.user_accounts[0].name} (
                                    {bets.user_accounts[0].code})
                                  </td>

                                  {bets.betTeam === "A" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? bets.teamA_lay_rate
                                        : bets.teamA_back_rate}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? bets.teamB_lay_rate
                                        : bets.teamB_back_rate}
                                    </td>
                                  )}

                                  <td style={{ padding: ".85rem" }}>
                                    {bets.amount}
                                  </td>

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>Lagai</td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>Khai</td>
                                  )}

                                  {bets.betTeam === "A" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {teamName1}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {teamName2}
                                    </td>
                                  )}

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {Number(bets.teamA_lay_amount)}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {Number(bets.teamA_bet_amount)}
                                    </td>
                                  )}

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {Number(bets.teamB_lay_amount)}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {Number(bets.teamB_bet_amount)}
                                    </td>
                                  )}

                                  <td style={{ padding: ".85rem" }}>
                                    {dateTimeFormatter(bets.createdAt)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9">No data found</td>
                              </tr>
                            )}
                            {matchBets?.length > 0 ? (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="text-center"
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {totalMatch1}
                                </td>
                                <td
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {totalMatch2}
                                </td>
                                <td
                                  colSpan="2"
                                  style={{ padding: ".85rem" }}
                                ></td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
