import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faHourglassEnd,
  faGamepad,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

export default function CasinoInPlay() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [data, setData] = useState([]);
  const [modalTeams, setModalTeams] = useState([]);
  const [matchId, setMatchId] = useState("");
  const accountType = localStorage.getItem("account");

  const handleAddGame = () => {
    navigate("/casino-add-button");
  };

  const handleLockMatch = (id, isLocked) => {
    const gameId = id;

    const payload = {
      isLocked: !isLocked,
    };
  };

  const setTeams = (team1, team2) => {
    const teams = [];
    teams.push({ id: "A", team: team1 });
    teams.push({ id: "B", team: team2 });
    teams.push({ id: "DRAW", team: "DRAW" });

    setModalTeams(teams);
  };

  return (
    <div className="content-wrapper">
      <PageHeader title="Casino In-Play" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Sports Details</h3>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleAddGame();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Game
                  </button>
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ padding: "0.75rem" }}>#</th>
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Code</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Date-Time Start</th>
                      <th style={{ padding: "0.75rem" }}>Date-Time End</th>
                      <th style={{ padding: "0.75rem" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      <>
                        {data?.map((item, index) => (
                          <tr key={index} id={`row${item.code}`}>
                            <td>
                              <div className="btn-group">
                                <button
                                  style={{
                                    padding: "6px 12px",
                                    lineHeight: "1.5",
                                  }}
                                  type="button"
                                  className="btn btn-outline-primary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="sr-only">
                                    Toggle Dropdown
                                  </span>
                                </button>
                                <div className="dropdown-menu" role="menu">
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname:
                                        "/inplay/match-session-position",
                                    }}
                                    state={item}
                                  >
                                    Match & Session Position
                                  </Link>

                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname:
                                        "/inplay/match-session-plus-minus",
                                    }}
                                    state={item}
                                  >
                                    Match Session Plus Minus
                                  </Link>

                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: "/inplay/match-session-bets",
                                    }}
                                    state={item}
                                  >
                                    Display Match & Session Bets
                                  </Link>

                                  <Link
                                    className="dropdown-item"
                                    to={`/inplay/match-bets?id=${item.id}`}
                                    state={{
                                      team1: item.team1,
                                      team2: item.team2,
                                    }}
                                  >
                                    Display Match Bets
                                  </Link>

                                  <Link
                                    className="dropdown-item"
                                    to={`/inplay/session-bets?id=${item.id}`}
                                  >
                                    Display Session Bets
                                  </Link>

                                  <button
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleLockMatch(item.id, item.isLocked);
                                    }}
                                  >
                                    {item.isLocked
                                      ? "Unlock Match"
                                      : "Lock Match"}
                                  </button>
                                </div>
                              </div>
                            </td>

                            <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                            <td style={{ padding: "0.75rem" }}>{item.code}</td>
                            <td style={{ padding: "0.75rem" }}>{item.name}</td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.server}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.match_type}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {!item.isLocked ? (
                                <button
                                  className="btn btn-sm btn-success"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-danger"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {accountType === "admin" ||
                              accountType === "power_user" ? (
                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                  <Link
                                    className="btn btn-sm btn-primary btn-orange"
                                    to={{
                                      pathname: "/inplay/edit-game",
                                    }}
                                    state={item}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-primary btn-danger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#endMatchModal"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setModalTeams([]);
                                      setMatchId(item.id);
                                      setTeams(item.team1, item.team2);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faHourglassEnd} />
                                  </button>
                                  <Link
                                    className="btn btn-sm btn-primary btn-warning"
                                    to={{
                                      pathname: "/inplay/control-game",
                                    }}
                                    state={item}
                                  >
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>
                                  <Link
                                    className="btn btn-sm btn-primary btn-danger"
                                    to={{
                                      pathname: "/inplay/decision-game",
                                    }}
                                    state={item}
                                  >
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
