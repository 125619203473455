import React from "react";
import "./header.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import user2image from "../img/user2-160x160.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const logout = () => {
    //alert("my fun call == ");
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const [userName, setuserName] = useState([]);
  const [userCode, setuserCode] = useState([]);

  useEffect(() => {
    const userName = localStorage.getItem("NAME");
    const userCode = localStorage.getItem("CODE");
    setuserName(userName);
    setuserCode(userCode);
  }, []);
  const Profile = () => {
    navigate("/profile");
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ToastContainer />

      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" role="button" onClick={toggleSidebar}>
            <i className="fas fa-bars icon-size-header">
              <FontAwesomeIcon icon={faBars} />
            </i>
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        <li className="nav-item dropdown user-menu">
          <a
            href="#"
            className="nav-link user-pic-code-size dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src={user2image}
              className="user-image img-circle elevation-2"
              alt="User Image"
            />
            {userCode}
          </a>
          <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            {/* User image */}
            <li className="user-header bg-primary ">
              <img
                src={user2image}
                className="img-circle elevation-2"
                alt="User Image"
              />
              <p>
                {userCode} {userName}
              </p>
            </li>
            {/* Menu Footer*/}
            <li className="user-footer">
              <button onClick={Profile} className="btn btn-default btn-flat">
                Profile
              </button>
              <button
                onClick={logout}
                className="btn btn-default btn-flat float-right"
              >
                Sign out
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
