/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./CompleteSubPages.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useGetMatchSessionAll from "../../hooks/Games/Complete/useGetMatchSessionAll";

export default function CompleteLongTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  const teamWon = UrlSearchParams.get("team");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");
  const userCode = localStorage.getItem("CODE");
  const userName = localStorage.getItem("NAME");

  const { mutate: getMatchSessionAll } = useGetMatchSessionAll();

  const [matchString, setMatchString] = useState("");
  const [finalReport, setFinalReport] = useState([]);
  // const [totalAmtClient, setTotalAmtClient] = useState(0);
  // const [totalAmtAgent, setTotalAmtAgent] = useState(0);
  // const [totalAmtSuperAgent, setTotalAmtSuperAgent] = useState(0);
  // const [totalAmtMaster, setTotalAmtMaster] = useState(0);
  // const [totalAmtSubAdmin, setTotalAmtSubAdmin] = useState(0);
  // const [totalAmtSuperAdmin, setTotalAmtSuperAdmin] = useState(0);

  const getCompanyReports = () => {
    let result__ = [];
    let match_string = "";

    const payload = {
      acc_type: accountType,
      user_id: userId,
      match_id: matchId,
    };

    getMatchSessionAll(payload, {
      onSuccess: (response) => {
        let game = response.data.Gamedata;

        match_string = `(${game.code}) ${game.name} (${game.match_type})`;
        setMatchString(match_string);

        let result = response.data.SessionData;

        let resp = [];
        // let total_amt_client = 0;
        // let total_amt_agent = 0;
        // let total_amt_super_agent = 0;
        // let total_amt_master = 0;
        // let total_amt_sub_admin = 0;
        // let total_amt_super_admin = 0;

        if (accountType === "admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined) {
              m_amt = el.bettingData[0].total_TeamB;
            }

            if (teamWon === "0") {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;

            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt === 0 &&
              s_amt_tot === 0
            ) {
              return;
            }

            s_amt = s_amt * -1;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;

            let m_comm = 0;
            let s_comm = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let client_m_com = 0;
            let mob_charge = 0;

            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_net_amt = m_amt + s_amt + c_com;
            let client_final =
              m_amt + s_amt + c_com + (mob_charge * el.mobile_share) / 100;
            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = client_final - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;
            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = agent_net_amt;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = agent_net_amt;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = agent_net_amt;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            let super_admin_t_com =
              super_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.parents.session_commission) /
                100;
            let super_admin_net_amt = 0;
            super_admin_net_amt = agent_net_amt;
            let super_admin_shr_amount =
              (super_admin_net_amt *
                el.parents.parents.parents.parents.parents.share) /
              100;
            let super_admin_final =
              super_admin_net_amt - super_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              client_id: el.id,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt - client_t_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
              super_admin: `(${el.parents.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.parents.name}`,
              super_admin_id: el.parents.parents.parents.parents.parents.id,
              super_admin_m_com: master_m_com,
              super_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.parents
                    .session_commission) /
                100,
              super_admin_t_com: super_admin_t_com,
              super_admin_shr_amt: super_admin_shr_amount,
              super_admin_net_amt: super_admin_net_amt,
              super_admin_mob_app: mob_charge,
              super_admin_final: super_admin_final,
            });
            // console.log(result__);
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.super_admin_id)) {
              resp.push({
                id: el.super_admin_id,
                name: el.super_admin,
                sub_admin: [],
              });
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            if (
              !resp[super_admin_index].sub_admin.some(
                (eb) => eb.id === el.sub_admin_id
              )
            ) {
              resp[super_admin_index].sub_admin.push({
                id: el.sub_admin_id,
                name: el.sub_admin,
                master: [],
              });
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              if (
                !resp[super_admin_index].sub_admin[sub_admin_index].master.some(
                  (eb) => eb.id === el.master_id
                )
              ) {
                resp[super_admin_index].sub_admin[sub_admin_index].master.push({
                  id: el.master_id,
                  name: el.master,
                  super_agent: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                if (
                  !resp[super_admin_index].sub_admin[sub_admin_index].master[
                    master_index
                  ].super_agent.some((eb) => eb.id === el.super_agent_id)
                ) {
                  resp[super_admin_index].sub_admin[sub_admin_index].master[
                    master_index
                  ].super_agent.push({
                    id: el.super_agent_id,
                    name: el.super_agent,
                    agent: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                const agent_index = resp[super_admin_index].sub_admin[
                  sub_admin_index
                ].master[master_index].super_agent.findIndex(
                  (em) => em.id === el.super_agent_id
                );
                if (agent_index !== -1) {
                  if (
                    !resp[super_admin_index].sub_admin[sub_admin_index].master[
                      master_index
                    ].super_agent[agent_index].agent.some(
                      (eb) => eb.id === el.agent_id
                    )
                  ) {
                    resp[super_admin_index].sub_admin[sub_admin_index].master[
                      master_index
                    ].super_agent[agent_index].agent.push({
                      id: el.agent_id,
                      name: el.agent,
                      client: [],
                    });
                  }
                }
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                const agent_index = resp[super_admin_index].sub_admin[
                  sub_admin_index
                ].master[master_index].super_agent.findIndex(
                  (em) => em.id === el.super_agent_id
                );
                if (agent_index !== -1) {
                  const client_index = resp[super_admin_index].sub_admin[
                    sub_admin_index
                  ].master[master_index].super_agent[
                    agent_index
                  ].agent.findIndex((em) => em.id === el.agent_id);
                  if (client_index !== -1) {
                    if (
                      !resp[super_admin_index].sub_admin[
                        sub_admin_index
                      ].master[master_index].super_agent[agent_index].agent[
                        client_index
                      ].client.some((eb) => eb.id === el.agent_id)
                    ) {
                      resp[super_admin_index].sub_admin[sub_admin_index].master[
                        master_index
                      ].super_agent[agent_index].agent[
                        client_index
                      ].client.push(el);
                      // agent_final  +=el.agent_final
                    }
                  }
                }
              }
            }
          });

          setFinalReport(resp);
        } else if (accountType === "super_admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined) {
              m_amt = el.bettingData[0].total_TeamB;
            }

            if (teamWon === "0") {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length !== 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare !== null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let mob_charge = 0;

            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;
            let client_net_amt = m_amt + s_amt + c_com;
            let client_final =
              m_amt + s_amt + c_com + (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = client_final - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;
            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            if (super_agent_t_com < 0) {
              super_agent_net_amt = agent_net_amt + super_agent_t_com;
            } else {
              super_agent_net_amt = agent_net_amt - super_agent_t_com;
            }
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = agent_net_amt;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = agent_net_amt;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            let super_admin_t_com =
              super_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.parents.session_commission) /
                100;
            let super_admin_net_amt = 0;
            super_admin_net_amt = agent_net_amt;
            let super_admin_shr_amount =
              (super_admin_net_amt *
                el.parents.parents.parents.parents.parents.share) /
              100;
            let super_admin_final =
              super_admin_net_amt - super_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.name,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
              super_admin: `(${el.parents.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.parents.name}`,
              super_admin_id: el.parents.parents.parents.parents.parents.id,
              super_admin_m_com: master_m_com,
              super_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.parents
                    .session_commission) /
                100,
              super_admin_t_com: super_admin_t_com,
              super_admin_shr_amt: super_admin_shr_amount,
              super_admin_net_amt: super_admin_net_amt,
              super_admin_mob_app: mob_charge,
              super_admin_final: super_admin_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.sub_admin_id)) {
              resp.push({
                id: el.sub_admin_id,
                name: el.sub_admin,
                master: [],
              });
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (
              !resp[sub_admin_index].master.some((eb) => eb.id === el.master_id)
            ) {
              resp[sub_admin_index].master.push({
                id: el.master_id,
                name: el.master,
                super_agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                if (
                  !resp[sub_admin_index].master[master_index].super_agent.some(
                    (eb) => eb.id === el.super_agent_id
                  )
                ) {
                  resp[sub_admin_index].master[master_index].super_agent.push({
                    id: el.super_agent_id,
                    name: el.super_agent,
                    agent: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                const agent_index = resp[sub_admin_index].master[
                  master_index
                ].super_agent.findIndex((em) => em.id === el.super_agent_id);
                if (agent_index !== -1) {
                  if (
                    !resp[sub_admin_index].master[master_index].super_agent[
                      agent_index
                    ].agent.some((eb) => eb.id === el.agent_id)
                  ) {
                    resp[sub_admin_index].master[master_index].super_agent[
                      agent_index
                    ].agent.push({
                      id: el.agent_id,
                      name: el.agent,
                      client: [],
                    });
                  }
                }
              }
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                const agent_index = resp[sub_admin_index].master[
                  master_index
                ].super_agent.findIndex((em) => em.id === el.super_agent_id);
                if (agent_index !== -1) {
                  const client_index = resp[sub_admin_index].master[
                    master_index
                  ].super_agent[agent_index].agent.findIndex(
                    (em) => em.id === el.agent_id
                  );
                  if (client_index !== -1) {
                    if (
                      !resp[sub_admin_index].master[master_index].super_agent[
                        agent_index
                      ].agent[client_index].client.some(
                        (eb) => eb.id === el.agent_id
                      )
                    ) {
                      resp[sub_admin_index].master[master_index].super_agent[
                        agent_index
                      ].agent[client_index].client.push(el);
                    }
                  }
                }
              }
            }
          });

          setFinalReport(resp);
        } else if (accountType === "sub_admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined) {
              m_amt = el.bettingData[0].total_TeamB;
            }

            if (teamWon === "0") {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let m_comm = 0;
            let client_m_com = 0;
            let s_comm = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_net_amt = m_amt + s_amt + c_com;
            let client_final =
              m_amt + s_amt + c_com + (mob_charge * el.mobile_share) / 100;
            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;

            agent_net_amt = client_final - agent_t_com;
            let agent_shr_amount = (el.parents.share / 100) * agent_net_amt;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            if (super_agent_t_com < 0) {
              super_agent_net_amt = agent_net_amt + super_agent_t_com;
            } else {
              super_agent_net_amt = agent_net_amt - super_agent_t_com;
            }
            super_agent_net_amt = agent_net_amt;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = agent_net_amt;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = agent_net_amt;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.master_id)) {
              resp.push({
                id: el.master_id,
                name: el.master,
                super_agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              if (
                !resp[master_index].super_agent.some(
                  (eb) => eb.id === el.super_agent_id
                )
              ) {
                resp[master_index].super_agent.push({
                  id: el.super_agent_id,
                  name: el.super_agent,
                  agent: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              const agent_index = resp[master_index].super_agent.findIndex(
                (em) => em.id === el.super_agent_id
              );
              if (agent_index !== -1) {
                if (
                  !resp[master_index].super_agent[agent_index].agent.some(
                    (eb) => eb.id === el.agent_id
                  )
                ) {
                  resp[master_index].super_agent[agent_index].agent.push({
                    id: el.agent_id,
                    name: el.agent,
                    client: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              const agent_index = resp[master_index].super_agent.findIndex(
                (em) => em.id === el.super_agent_id
              );
              if (agent_index !== -1) {
                const client_index = resp[master_index].super_agent[
                  agent_index
                ].agent.findIndex((em) => em.id === el.agent_id);
                if (client_index !== -1) {
                  if (
                    !resp[master_index].super_agent[agent_index].agent[
                      client_index
                    ].client.some((eb) => eb.id === el.agent_id)
                  ) {
                    resp[master_index].super_agent[agent_index].agent[
                      client_index
                    ].client.push(el);
                  }
                }
              }
            }
          });

          setFinalReport(resp);
        } else if (accountType === "master") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined) {
              m_amt = el.bettingData[0].total_TeamB;
            }

            if (teamWon === "0") {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_net_amt = m_amt + s_amt + c_com;
            let client_final =
              m_amt + s_amt + c_com + (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = client_final - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;
            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = agent_net_amt;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = agent_net_amt;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.super_agent_id)) {
              resp.push({
                id: el.super_agent_id,
                name: el.super_agent,
                agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex(
              (em) => em.id === el.super_agent_id
            );
            if (agent_index !== -1) {
              if (
                !resp[agent_index].agent.some((eb) => eb.id === el.agent_id)
              ) {
                resp[agent_index].agent.push({
                  id: el.agent_id,
                  name: el.agent,
                  client: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex(
              (em) => em.id === el.super_agent_id
            );
            if (agent_index !== -1) {
              const client_index = resp[agent_index].agent.findIndex(
                (em) => em.id === el.agent_id
              );
              if (client_index !== -1) {
                if (
                  !resp[agent_index].agent[client_index].client.some(
                    (eb) => eb.id === el.agent_id
                  )
                ) {
                  resp[agent_index].agent[client_index].client.push(el);
                }
              }
            }
          });

          setFinalReport(resp);
        } else if (accountType === "super_agent" || accountType === "agent") {
          result.forEach((el) => {
            console.log(el);
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined) {
              m_amt = el.bettingData[0].total_TeamB;
            }

            if (teamWon === "0") {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_net_amt = m_amt + s_amt + c_com;
            let client_final =
              m_amt + s_amt + c_com + (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            agent_net_amt = client_final - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = agent_net_amt;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              client_id: el.id,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.agent_id)) {
              resp.push({
                id: el.agent_id,
                name: el.agent,
                client: [],
              });
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex((em) => em.id === el.agent_id);
            if (agent_index != -1) {
              if (
                !resp[agent_index].client.some((eb) => eb.id === el.client_id)
              ) {
                resp[agent_index].client.push(el);
              }
            }
          });

          setFinalReport(resp);
        }

        // result__.forEach((el) => {
        //   total_amt_client = total_amt_client + el.final;
        //   total_amt_agent = total_amt_agent + el.agent_final;
        //   total_amt_super_agent = total_amt_super_agent + el.super_agent_final;
        //   total_amt_master = total_amt_master + el.master_final;
        //   total_amt_sub_admin = total_amt_sub_admin + el.sub_admin_final;
        //   total_amt_super_admin = total_amt_super_admin + el.super_admin_final;
        // });

        // setTotalAmtClient(total_amt_client);
        // setTotalAmtAgent(total_amt_agent);
        // setTotalAmtSuperAgent(total_amt_super_agent);
        // setTotalAmtMaster(total_amt_master);
        // setTotalAmtSubAdmin(total_amt_sub_admin);
        // setTotalAmtSuperAdmin(total_amt_super_admin);
      },
      onError: (error) => {
        toast.error("Something went wrong", error);
      },
    });
  };

  const sumKey = (arr, key) => {
    return Number(arr.reduce((a, b) => +a + +b[key], 0)).toFixed(2);
  };

  useEffect(() => {
    getCompanyReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match & Session Plus Minus Report</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/complete-games">Complete Games</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match & Session Plus Minus Report</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card long-table-11">
              <div
                className="card-header bg-white"
                style={{
                  width:
                    finalReport.length > 0
                      ? accountType === "admin"
                        ? "5000px"
                        : accountType === "super_admin"
                        ? "4000px"
                        : accountType === "sub_admin"
                        ? "3000px"
                        : "2000px"
                      : "inherit",
                }}
              >
                <h5 className="mb-0">MATCH CODE : {matchString}</h5>
              </div>

              <div
                className="card-body bg-white"
                style={{
                  width:
                    finalReport.length > 0
                      ? accountType === "admin"
                        ? "5000px"
                        : accountType === "super_admin"
                        ? "4000px"
                        : accountType === "sub_admin"
                        ? "3000px"
                        : "2000px"
                      : "inherit",
                }}
              >
                {finalReport.length > 0 ? (
                  accountType === "admin" ? (
                    <div className="card">
                      <div
                        className="card-header bg-dark py-2 text-white"
                        style={{ height: "44px" }}
                      >
                        ADMIN : ({userCode}) {userName}
                      </div>
                      <div className="card-body bg-gray-light pt-4">
                        {finalReport.map((superAdmin, index) => (
                          <div className="card" key={index}>
                            <div
                              className="card-header bg-danger py-2 text-white"
                              style={{ height: "44px" }}
                            >
                              SUPER ADMIN : {superAdmin.name}
                            </div>
                            <div className="card-body pt-4">
                              {superAdmin.sub_admin.map((sub_admin, index) => (
                                <div className="card" key={index}>
                                  <div
                                    className="card-header bg-green py-2 text-white"
                                    style={{ height: "44px" }}
                                  >
                                    SUBADMIN NAME : {sub_admin.name}
                                  </div>
                                  <div className="card-body bg-gray-light pt-4">
                                    {sub_admin.master.map((master, index) => (
                                      <div className="card" key={index}>
                                        <div
                                          className="card-header bg-warning py-2 text-white"
                                          style={{ height: "44px" }}
                                        >
                                          MASTER NAME : {master.name}
                                        </div>
                                        <div className="card-body pt-4">
                                          {master.super_agent.map(
                                            (super_agent, index) => (
                                              <div className="card" key={index}>
                                                <div
                                                  className="card-header bg-purple py-2 text-white"
                                                  style={{ height: "44px" }}
                                                >
                                                  SUPERAGENT NAME :{" "}
                                                  {super_agent.name}
                                                </div>
                                                <div className="card-body bg-gray-light pt-4">
                                                  {super_agent.agent.map(
                                                    (agent, index) => (
                                                      <div
                                                        className="card"
                                                        key={index}
                                                      >
                                                        <div
                                                          className="card-header bg-primary py-2 text-white mb-3"
                                                          style={{
                                                            lineHeight: 1.5,
                                                            height: "44px",
                                                          }}
                                                        >
                                                          AGENT NAME :{" "}
                                                          {agent.name}
                                                        </div>
                                                        <div className="card-body">
                                                          <div className="table">
                                                            <table
                                                              className="table table-bordered table-striped mb-0 longTable"
                                                              style={{
                                                                lineHeight: 1.5,
                                                              }}
                                                            >
                                                              <thead>
                                                                <tr>
                                                                  <th colSpan="11">
                                                                    Agent
                                                                  </th>
                                                                  <th colSpan="7">
                                                                    Super Agent
                                                                  </th>
                                                                  <th colSpan="7">
                                                                    Master
                                                                  </th>
                                                                  <th colSpan="7">
                                                                    Sub Admin
                                                                  </th>
                                                                  <th colSpan="7">
                                                                    Super Admin
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr>
                                                                  <th>
                                                                    Client
                                                                  </th>
                                                                  <th>M Amt</th>
                                                                  <th>S Amt</th>
                                                                  <th>T Amt</th>
                                                                  {/* <th>
                                                                    M Comm
                                                                  </th>
                                                                  <th>
                                                                    S Comm
                                                                  </th>
                                                                  <th>
                                                                    T Comm
                                                                  </th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  {/* <th>C Mob</th> 
                                                                  <th>Final</th> */}
                                                                  <th>M Com</th>
                                                                  <th>S Com</th>
                                                                  <th>T Com</th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  <th>
                                                                    Shr Amt
                                                                  </th>
                                                                  <th>
                                                                    Mob App
                                                                  </th>
                                                                  <th>Final</th>
                                                                  <th>M Com</th>
                                                                  <th>S Com</th>
                                                                  <th>T Com</th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  <th>
                                                                    Shr Amt
                                                                  </th>
                                                                  <th>
                                                                    Mob App
                                                                  </th>
                                                                  <th>Final</th>
                                                                  <th>M Com</th>
                                                                  <th>S Com</th>
                                                                  <th>T Com</th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  <th>
                                                                    Shr Amt
                                                                  </th>
                                                                  <th>
                                                                    Mob App
                                                                  </th>
                                                                  <th>Final</th>
                                                                  <th>M Com</th>
                                                                  <th>S Com</th>
                                                                  <th>T Com</th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  <th>
                                                                    Shr Amt
                                                                  </th>
                                                                  <th>
                                                                    Mob App
                                                                  </th>
                                                                  <th>Final</th>
                                                                  <th>M Com</th>
                                                                  <th>S Com</th>
                                                                  <th>T Com</th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  <th>
                                                                    Shr Amt
                                                                  </th>
                                                                  <th>
                                                                    Mob App
                                                                  </th>
                                                                  <th>Final</th>
                                                                </tr>

                                                                {agent.client.map(
                                                                  (
                                                                    match,
                                                                    index
                                                                  ) => (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td>
                                                                        {
                                                                          match.client
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {match.m_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.s_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.t_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      {/* <td>
                                                                        {match.m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.c_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td> */}

                                                                      {/*<td>
                                                                    {match.c_mob.toFixed(
                                                                      2
                                                                    )}
                                                                  </td>*/}
                                                                      {/* <td>
                                                                        {match.net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td> */}

                                                                      <td>
                                                                        {match.agent_m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_t_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_shr_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_mob_app.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.agent_final.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_t_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_shr_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_mob_app.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_agent_final.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_t_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_shr_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_mob_app.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.master_final.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_t_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_shr_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_mob_app.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.sub_admin_final.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_t_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_shr_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_mob_app.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.super_admin_final.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )}

                                                                <tr>
                                                                  <td>Total</td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "m_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "s_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "t_amt"
                                                                    )}
                                                                  </td>
                                                                  {/* <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "c_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "net_amt"
                                                                    )}
                                                                  </td> */}
                                                                  {/* <td>
                                                                {sumKey(
                                                                  agent.client,
                                                                  "c_mob"
                                                                )}
                                                              </td> */}
                                                                  {/* <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "net_amt"
                                                                    )}
                                                                  </td> */}
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_t_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_net_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_shr_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_mob_app"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "agent_final"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_t_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_net_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_shr_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_mob_app"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_agent_final"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_t_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_net_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_shr_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_mob_app"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "master_final"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_t_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_net_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_shr_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_mob_app"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "sub_admin_final"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_t_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_net_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_shr_amt"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_mob_app"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "super_admin_final"
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : accountType === "super_admin" ? (
                    <div className="card">
                      <div
                        className="card-header bg-danger py-2 text-white"
                        style={{ height: "44px" }}
                      >
                        SUPER ADMIN : ({userCode}) {userName}
                      </div>
                      <div className="card-body pt-4">
                        {finalReport.map((sub_admin, index) => (
                          <div className="card" key={index}>
                            <div className="card-header bg-green py-2 text-white">
                              SUBADMIN NAME : {sub_admin.name}
                            </div>
                            <div className="card-body bg-gray-light pt-4">
                              {sub_admin.master.map((master, index) => (
                                <div className="card" key={index}>
                                  <div className="card-header bg-warning py-2 text-white">
                                    MASTER NAME : {master.name}
                                  </div>
                                  <div className="card-body pt-4">
                                    {master.super_agent.map(
                                      (super_agent, index) => (
                                        <div className="card" key={index}>
                                          <div className="card-header bg-purple py-2 text-white">
                                            SUPERAGENT NAME : {super_agent.name}
                                          </div>
                                          <div className="card-body bg-gray-light pt-4">
                                            {super_agent.agent.map(
                                              (agent, index) => (
                                                <div
                                                  className="card"
                                                  key={index}
                                                >
                                                  <div
                                                    className="card-header bg-primary py-2"
                                                    style={{
                                                      lineHeight: 1.5,
                                                    }}
                                                  >
                                                    AGENT NAME : {agent.name}
                                                  </div>
                                                  <div className="card-body pt-4">
                                                    <div className="table pt-2">
                                                      <table
                                                        className="table table-bordered table-striped mb-0 longTable"
                                                        style={{
                                                          lineHeight: 1.5,
                                                        }}
                                                      >
                                                        <thead>
                                                          <tr>
                                                            <th colSpan="11">
                                                              Agent
                                                            </th>
                                                            <th colSpan="7">
                                                              Super Agent
                                                            </th>
                                                            <th colSpan="7">
                                                              Master
                                                            </th>
                                                            <th colSpan="7">
                                                              Sub Admin
                                                            </th>
                                                            <th colSpan="7">
                                                              Super Admin
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <th>Client</th>
                                                            <th>M Amt</th>
                                                            <th>S Amt</th>
                                                            <th>T Amt</th>
                                                            {/*<th>T Comm</th>
                                                            <th>Net Amt</th>
                                                            <th>C Mob</th> 
                                                            <th>Final</th> */}
                                                            <th>M Com</th>
                                                            <th>S Com</th>
                                                            <th>T Com</th>
                                                            <th>Net Amt</th>
                                                            <th>Shr Amt</th>
                                                            <th>Mob App</th>
                                                            <th>Final</th>
                                                            <th>M Com</th>
                                                            <th>S Com</th>
                                                            <th>T Com</th>
                                                            <th>Net Amt</th>
                                                            <th>Shr Amt</th>
                                                            <th>Mob App</th>
                                                            <th>Final</th>
                                                            <th>M Com</th>
                                                            <th>S Com</th>
                                                            <th>T Com</th>
                                                            <th>Net Amt</th>
                                                            <th>Shr Amt</th>
                                                            <th>Mob App</th>
                                                            <th>Final</th>
                                                            <th>M Com</th>
                                                            <th>S Com</th>
                                                            <th>T Com</th>
                                                            <th>Net Amt</th>
                                                            <th>Shr Amt</th>
                                                            <th>Mob App</th>
                                                            <th>Final</th>
                                                            <th>M Com</th>
                                                            <th>S Com</th>
                                                            <th>T Com</th>
                                                            <th>Net Amt</th>
                                                            <th>Shr Amt</th>
                                                            <th>Mob App</th>
                                                            <th>Final</th>
                                                          </tr>

                                                          {agent.client.map(
                                                            (match, index) => (
                                                              <tr key={index}>
                                                                <td>
                                                                  {match.client}
                                                                </td>
                                                                <td>
                                                                  {match.m_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.s_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.t_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                {/* <td>
                                                                  {match.c_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td> */}
                                                                {/* <td>
                                                              {match.c_mob.toFixed(
                                                                2
                                                              )}
                                                            </td> */}
                                                                {/* <td>
                                                                  {match.net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td> */}
                                                                <td>
                                                                  {match.agent_m_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_s_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_t_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_shr_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_mob_app.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.agent_final.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_m_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_s_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_t_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_shr_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_mob_app.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_agent_final.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_m_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_s_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_t_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_shr_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_mob_app.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.master_final.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_m_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_s_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_t_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_shr_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_mob_app.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.sub_admin_final.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_m_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_s_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_t_com.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_net_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_shr_amt.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_mob_app.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {match.super_admin_final.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}

                                                          <tr>
                                                            <td>Total</td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "m_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "s_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "t_amt"
                                                              )}
                                                            </td>

                                                            {/* <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "c_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "net_amt"
                                                              )}
                                                            </td> */}
                                                            {/* <td>
                                                          {sumKey(
                                                            agent.client,
                                                            "c_mob"
                                                          )}
                                                        </td> */}
                                                            {/* <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "net_amt"
                                                              )}
                                                            </td> */}
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_m_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_s_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_t_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_net_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_shr_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_mob_app"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "agent_final"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_m_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_s_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_t_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_net_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_shr_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_mob_app"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_agent_final"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_m_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_s_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_t_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_net_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_shr_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_mob_app"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "master_final"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_m_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_s_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_t_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_net_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_shr_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_mob_app"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "sub_admin_final"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_m_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_s_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_t_com"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_net_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_shr_amt"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_mob_app"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {sumKey(
                                                                agent.client,
                                                                "super_admin_final"
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : accountType === "sub_admin" ? (
                    <div className="card">
                      <div
                        className="card-header bg-green py-2 text-white"
                        style={{ height: "44px" }}
                      >
                        SUB ADMIN : ({userCode}) {userName}
                      </div>
                      <div className="card-body bg-gray-light pt-4">
                        {finalReport.map((master, index) => (
                          <div className="card" key={index}>
                            <div className="card-header bg-warning py-2 text-white">
                              MASTER NAME : {master.name}
                            </div>
                            <div className="card-body pt-4">
                              {master.super_agent.map((super_agent, index) => (
                                <div className="card" key={index}>
                                  <div className="card-header bg-purple py-2 text-white">
                                    SUPERAGENT NAME : {super_agent.name}
                                  </div>
                                  <div className="card-body bg-gray-light pt-4">
                                    {super_agent.agent.map((agent, index) => (
                                      <div className="card" key={index}>
                                        <div
                                          className="card-header bg-primary py-2"
                                          style={{
                                            lineHeight: 1.5,
                                          }}
                                        >
                                          AGENT NAME : {agent.name}
                                        </div>
                                        <div className="card-body pt-4">
                                          <div className="table">
                                            <table
                                              className="table table-bordered table-striped mb-0 longTable"
                                              style={{
                                                lineHeight: 1.5,
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th colSpan="11">Agent</th>
                                                  <th colSpan="7">
                                                    Super Agent
                                                  </th>
                                                  <th colSpan="7">Master</th>
                                                  <th colSpan="7">Sub Admin</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th>Client</th>
                                                  <th>M Amt</th>
                                                  <th>S Amt</th>
                                                  <th>T Amt</th>
                                                  {/* <th>T Comm</th>
                                                  <th>Net Amt</th>
                                                  {/* <th>C Mob</th>
                                                  <th>Final</th> */}
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                </tr>

                                                {agent.client.map(
                                                  (match, index) => (
                                                    <tr key={index}>
                                                      <td>{match.client}</td>
                                                      <td>
                                                        {match.m_amt.toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {match.s_amt.toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {match.t_amt.toFixed(2)}
                                                      </td>
                                                      {/* <td>
                                                        {match.c_com.toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {match.net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td> */}
                                                      {/* <td>
                                                    {match.c_mob.toFixed(2)}
                                                  </td> */}
                                                      {/* <td>
                                                        {match.net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td> */}
                                                      <td>
                                                        {match.agent_m_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_s_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_t_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_shr_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_mob_app.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.agent_final.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_m_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_s_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_t_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_shr_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_mob_app.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.super_agent_final.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_m_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_s_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_t_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_shr_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_mob_app.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.master_final.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_m_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_s_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_t_com.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_net_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_shr_amt.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_mob_app.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {match.sub_admin_final.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}

                                                <tr>
                                                  <td>Total</td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "m_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "s_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "t_amt"
                                                    )}
                                                  </td>

                                                  {/* <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "c_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "net_amt"
                                                    )}
                                                  </td> */}
                                                  {/* <td>
                                                {sumKey(agent.client, "c_mob")}
                                              </td> */}
                                                  {/* <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "net_amt"
                                                    )}
                                                  </td> */}
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_m_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_s_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_t_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_net_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_shr_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_mob_app"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "agent_final"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_m_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_s_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_t_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_net_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_shr_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_mob_app"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "super_agent_final"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_m_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_s_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_t_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_net_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_shr_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_mob_app"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "master_final"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_m_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_s_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_t_com"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_net_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_shr_amt"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_mob_app"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {sumKey(
                                                      agent.client,
                                                      "sub_admin_final"
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : accountType === "master" ? (
                    <div className="card">
                      <div
                        className="card-header bg-warning py-2 text-white"
                        style={{ height: "44px" }}
                      >
                        MASTER : ({userCode}) {userName}
                      </div>
                      <div className="card-body pt-4">
                        {finalReport.map((super_agent, index) => (
                          <div className="card" key={index}>
                            <div className="card-header bg-purple py-2 text-white">
                              SUPERAGENT NAME : {super_agent.name}
                            </div>
                            <div className="card-body bg-gray-light pt-4">
                              {super_agent.agent.map((agent, index) => (
                                <div className="card" key={index}>
                                  <div
                                    className="card-header bg-primary py-2"
                                    style={{
                                      lineHeight: 1.5,
                                    }}
                                  >
                                    AGENT NAME : {agent.name}
                                  </div>
                                  <div className="card-body pt-4">
                                    <div className="table">
                                      <table
                                        className="table table-bordered table-striped mb-0 longTable"
                                        style={{
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th colSpan="11">Agent</th>
                                            <th colSpan="7">Super Agent</th>
                                            <th colSpan="7">Master</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th>Client</th>
                                            <th>M Amt</th>
                                            <th>S Amt</th>
                                            <th>T Amt</th>
                                            {/* <th>T Comm</th>
                                            <th>Net Amt</th>
                                            {/* <th>C Mob</th>
                                            <th>Final</th> */}
                                            <th>M Com</th>
                                            <th>S Com</th>
                                            <th>T Com</th>
                                            <th>Net Amt</th>
                                            <th>Shr Amt</th>
                                            <th>Mob App</th>
                                            <th>Final</th>
                                            <th>M Com</th>
                                            <th>S Com</th>
                                            <th>T Com</th>
                                            <th>Net Amt</th>
                                            <th>Shr Amt</th>
                                            <th>Mob App</th>
                                            <th>Final</th>
                                            <th>M Com</th>
                                            <th>S Com</th>
                                            <th>T Com</th>
                                            <th>Net Amt</th>
                                            <th>Shr Amt</th>
                                            <th>Mob App</th>
                                            <th>Final</th>
                                          </tr>

                                          {agent.client.map((match, index) => (
                                            <tr key={index}>
                                              <td>{match.client}</td>
                                              <td>{match.m_amt.toFixed(2)}</td>
                                              <td>{match.s_amt.toFixed(2)}</td>
                                              <td>{match.t_amt.toFixed(2)}</td>
                                              {/* <td>{match.c_com.toFixed(2)}</td>
                                              <td>
                                                {match.net_amt.toFixed(2)}
                                              </td>
                                              {/* <td>{match.c_mob.toFixed(2)}</td>
                                              <td>
                                                {match.net_amt.toFixed(2)}
                                              </td> */}
                                              <td>
                                                {match.agent_m_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_s_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_t_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_net_amt.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_shr_amt.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_mob_app.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.agent_final.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.super_agent_m_com.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_s_com.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_t_com.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_net_amt.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_shr_amt.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_mob_app.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.super_agent_final.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.master_m_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.master_s_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.master_t_com.toFixed(2)}
                                              </td>
                                              <td>
                                                {match.master_net_amt.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.master_shr_amt.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.master_mob_app.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {match.master_final.toFixed(2)}
                                              </td>
                                            </tr>
                                          ))}

                                          <tr>
                                            <td>Total</td>
                                            <td>
                                              {sumKey(agent.client, "m_amt")}
                                            </td>
                                            <td>
                                              {sumKey(agent.client, "s_amt")}
                                            </td>
                                            <td>
                                              {sumKey(agent.client, "t_amt")}
                                            </td>
                                            {/* <td>
                                              {sumKey(agent.client, "c_com")}
                                            </td>
                                            <td>
                                              {sumKey(agent.client, "net_amt")}
                                            </td>
                                            {/* <td>{sumKey(agent.client, "c_mob")}</td>
                                            <td>
                                              {sumKey(agent.client, "net_amt")}
                                            </td> */}
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_m_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_s_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_t_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_net_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_shr_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_mob_app"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "agent_final"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_m_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_s_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_t_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_net_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_shr_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_mob_app"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "super_agent_final"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_m_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_s_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_t_com"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_net_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_shr_amt"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_mob_app"
                                              )}
                                            </td>
                                            <td>
                                              {sumKey(
                                                agent.client,
                                                "master_final"
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : accountType === "super_agent" ? (
                    <div className="card">
                      <div
                        className="card-header bg-purple py-2 text-white"
                        style={{ height: "44px" }}
                      >
                        SUPER AGENT : ({userCode}) {userName}
                      </div>
                      <div className="card-body bg-gray-light pt-4">
                        {finalReport.map((agent, index) => (
                          <div className="card" key={index}>
                            <div
                              className="card-header bg-primary py-2"
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              AGENT NAME : {agent.name}
                            </div>
                            <div className="card-body pt-4">
                              <div className="table">
                                <table
                                  className="table table-bordered table-striped mb-0 longTable"
                                  style={{
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th colSpan="11">Agent</th>
                                      <th colSpan="7">Super Agent</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th>Client</th>
                                      <th>M Amt</th>
                                      <th>S Amt</th>
                                      <th>T Amt</th>
                                      {/* <th>T Comm</th>
                                            <th>Net Amt</th>
                                            {/* <th>C Mob</th>
                                            <th>Final</th> */}
                                      <th>M Com</th>
                                      <th>S Com</th>
                                      <th>T Com</th>
                                      <th>Net Amt</th>
                                      <th>Shr Amt</th>
                                      <th>Mob App</th>
                                      <th>Final</th>
                                      <th>M Com</th>
                                      <th>S Com</th>
                                      <th>T Com</th>
                                      <th>Net Amt</th>
                                      <th>Shr Amt</th>
                                      <th>Mob App</th>
                                      <th>Final</th>
                                    </tr>

                                    {agent.client.map((match, index) => (
                                      <tr key={index}>
                                        <td>{match.client}</td>
                                        <td>{match.m_amt.toFixed(2)}</td>
                                        <td>{match.s_amt.toFixed(2)}</td>
                                        <td>{match.t_amt.toFixed(2)}</td>
                                        {/* <td>{match.c_com.toFixed(2)}</td>
                                              <td>{match.net_amt.toFixed(2)}</td>
                                              {/* <td>{match.c_mob.toFixed(2)}</td>
                                              <td>{match.net_amt.toFixed(2)}</td> */}
                                        <td>{match.agent_m_com.toFixed(2)}</td>
                                        <td>{match.agent_s_com.toFixed(2)}</td>
                                        <td>{match.agent_t_com.toFixed(2)}</td>
                                        <td>
                                          {match.agent_net_amt.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.agent_shr_amt.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.agent_mob_app.toFixed(2)}
                                        </td>
                                        <td>{match.agent_final.toFixed(2)}</td>
                                        <td>
                                          {match.super_agent_m_com.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_s_com.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_t_com.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_net_amt.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_shr_amt.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_mob_app.toFixed(2)}
                                        </td>
                                        <td>
                                          {match.super_agent_final.toFixed(2)}
                                        </td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td>Total</td>
                                      <td>{sumKey(agent.client, "m_amt")}</td>
                                      <td>{sumKey(agent.client, "s_amt")}</td>
                                      <td>{sumKey(agent.client, "t_amt")}</td>
                                      {/* <td>{sumKey(agent.client, "c_com")}</td>
                                            <td>{sumKey(agent.client, "net_amt")}</td>
                                            {/* <td>{sumKey(agent.client, "c_mob")}</td>
                                            <td>{sumKey(agent.client, "net_amt")}</td> */}
                                      <td>
                                        {sumKey(agent.client, "agent_m_com")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_s_com")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_t_com")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_net_amt")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_shr_amt")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_mob_app")}
                                      </td>
                                      <td>
                                        {sumKey(agent.client, "agent_final")}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_m_com"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_s_com"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_t_com"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_net_amt"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_shr_amt"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_mob_app"
                                        )}
                                      </td>
                                      <td>
                                        {sumKey(
                                          agent.client,
                                          "super_agent_final"
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    finalReport.map((agent, index) => (
                      <div className="card" key={index}>
                        <div
                          className="card-header bg-primary py-2"
                          style={{
                            lineHeight: 1.5,
                          }}
                        >
                          AGENT NAME : {agent.name}
                        </div>
                        <div className="card-body pt-4">
                          <div className="table">
                            <table
                              className="table table-bordered table-striped mb-0 longTable"
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              <thead>
                                <tr>
                                  <th colSpan="6">Client Plus/Minus</th>
                                  <th colSpan="7">Agent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Client</th>
                                  <th>M Amt</th>
                                  <th>S Amt</th>
                                  {/* <th>T Amt</th> */}
                                  <th>C Comm</th>
                                  <th>Net Amt</th>
                                  {/* <th>C Mob</th> */}
                                  <th>Final</th>
                                  <th>M Com</th>
                                  <th>S Com</th>
                                  <th>T Com</th>
                                  <th>Net Amt</th>
                                  <th>Shr Amt</th>
                                  <th>Mob App</th>
                                  <th>Final</th>
                                </tr>

                                {agent.client.map((match, index) => (
                                  <tr key={index}>
                                    <td>{match.client}</td>
                                    <td>{match.m_amt.toFixed(2)}</td>
                                    <td>{match.s_amt.toFixed(2)}</td>
                                    {/* <td>{match.t_amt.toFixed(2)}</td> */}
                                    <td>{match.c_com.toFixed(2)}</td>
                                    <td>{match.net_amt.toFixed(2)}</td>
                                    {/* <td>{match.c_mob.toFixed(2)}</td>*/}
                                    <td>{match.final.toFixed(2)}</td>
                                    <td>{match.agent_m_com.toFixed(2)}</td>
                                    <td>{match.agent_s_com.toFixed(2)}</td>
                                    <td>{match.agent_t_com.toFixed(2)}</td>
                                    <td>{match.agent_net_amt.toFixed(2)}</td>
                                    <td>{match.agent_shr_amt.toFixed(2)}</td>
                                    <td>{match.agent_mob_app.toFixed(2)}</td>
                                    <td>{match.agent_final.toFixed(2)}</td>
                                  </tr>
                                ))}

                                <tr>
                                  <td>Total</td>
                                  <td>{sumKey(agent.client, "m_amt")}</td>
                                  <td>{sumKey(agent.client, "s_amt")}</td>
                                  {/* <td>{sumKey(agent.client, "t_amt")}</td> */}
                                  <td>{sumKey(agent.client, "c_com")}</td>
                                  <td>{sumKey(agent.client, "net_amt")}</td>
                                  {/* <td>{sumKey(agent.client, "c_mob")}</td>*/}
                                  <td>{sumKey(agent.client, "final")}</td>
                                  <td>{sumKey(agent.client, "agent_m_com")}</td>
                                  <td>{sumKey(agent.client, "agent_s_com")}</td>
                                  <td>{sumKey(agent.client, "agent_t_com")}</td>
                                  <td>
                                    {sumKey(agent.client, "agent_net_amt")}
                                  </td>
                                  <td>
                                    {sumKey(agent.client, "agent_shr_amt")}
                                  </td>
                                  <td>
                                    {sumKey(agent.client, "agent_mob_app")}
                                  </td>
                                  <td>{sumKey(agent.client, "agent_final")}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  <div className="alert alert-danger">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
