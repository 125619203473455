/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";
import useGetClientMasterList from "../../hooks/ClientMaster/useGetClientMasterList";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import Select from "react-select";

export default function InplayMatchSessionBets() {
  const navigate = useNavigate();
  const location = useLocation();
  let gameData = [];
  let gameId = "";
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  if (location.state === undefined) {
    navigate("/inplay");
  }

  if (location.state) {
    gameData = location.state;
    gameId = location.state.id;
  }

  const { mutate: getBetsData } = useGetBetsData();

  const { mutate: getUserOnlySession } = useGetUserOnlySession();
  const { data: ClientMasterListData } = useGetClientMasterList();

  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [matchBets, setMatchBets] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [allSessions, setAllSessions] = useState([]);

  const [totalMatch1, setTotalMatch1] = useState(0);
  const [totalMatch2, setTotalMatch2] = useState(0);
  const [totalMatch3, setTotalMatch3] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const filterData = (value) => {
    if (value === "All" || value === "") {
      setMatchBets(allMatches);
      setSessionData(allSessions);
    } else {
      const filteredBets = allMatches.filter(
        (item) => item.user_accounts[0].id === value
      );
      const filteredSessions = allSessions.filter(
        (item) => item.user_account.id === value
      );

      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      filteredBets.forEach((el) => {
        total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
        total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
        total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
      });

      let totalAmount = 0;
      filteredSessions.forEach((el) => {
        totalAmount += el.amount;
      });

      setTotalMatch1(Math.round(total1));
      setTotalMatch2(Math.round(total2));
      setTotalMatch3(Math.round(total3));

      setTotalAmount(totalAmount);

      setMatchBets(filteredBets);
      setSessionData(filteredSessions);
    }
  };

  useEffect(() => {
    const listData = ClientMasterListData?.data;

    if (listData) {
      listData?.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
      setCompleteData(listData);
    }
  }, [ClientMasterListData]);

  useEffect(() => {
    const payload = {
      match_id: gameId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(payload, {
      onSuccess: (data) => {
        setMatchBets(data.data);
        setAllMatches(data.data);

        let total1 = 0;
        let total2 = 0;
        let total3 = 0;

        data.data.forEach((el) => {
          total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
          total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
          total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
        });

        setTotalMatch1(Math.round(total1));
        setTotalMatch2(Math.round(total2));
        setTotalMatch3(Math.round(total3));
      },
      onError: (error) => {
        console.log(error);
      },
    });

    getUserOnlySession(payload, {
      onSuccess: (data) => {
        setSessionData(data.data);
        setAllSessions(data.data);
        const calculateTotalAmount = (data) => {
          let totalAmount = 0;
          data.forEach((el) => {
            totalAmount += el.amount;
          });
          setTotalAmount(totalAmount);
        };

        calculateTotalAmount(data.data); // Corrected here
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match & Session Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match & Session Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            {/* /.card */}
            <div className="card">
              <div className="card-header">
                <div className="form-group col-md-4 text-black">
                  <label
                    className="form-label card-title"
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      marginTop: "5px",
                    }}
                  >
                    Client
                  </label>
                  <Select
                    options={[
                      { value: "", label: "Select" },
                      { value: "All", label: "All" },
                      ...data.map((item) => ({
                        value: item.id,
                        label: `${item.name} (${item.code})`,
                      })),
                    ]}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={""}
                    isDisabled={false}
                    isLoading={data.length === 0 ? true : false}
                    isSearchable={true}
                    isRtl={false}
                    name="selectedUser"
                    onChange={(e) => {
                      const selected = e?.value;
                      filterData(selected);
                    }}
                  />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row w-100 align-items-start">
                  <div className="col-md-6">
                    {/*<h6 class="bg-secondary text-center text-white text-md mb-0 py-3">
                      MATCH BETS
                  </h6>*/}

                    <table className="table table-responsive table-striped table-bordered">
                      <thead>
                        <th
                          colSpan="9"
                          className="inplay-match-bet-table bg-secondary text-md mb-0 py-3"
                        >
                          MATCH BETS
                        </th>
                      </thead>
                      <thead>
                        <tr>
                          <th style={{ padding: ".85rem" }}>Name</th>
                          <th style={{ padding: ".85rem" }}>User</th>
                          <th style={{ padding: ".85rem" }}>Rate</th>
                          <th style={{ padding: ".85rem" }}>Amount</th>
                          <th style={{ padding: ".85rem" }}>Mode</th>
                          <th style={{ padding: ".85rem" }}>Team</th>
                          <th style={{ padding: ".85rem" }}>Date & Time</th>
                          <th style={{ padding: ".85rem" }}>
                            {gameData.team1}
                          </th>
                          <th style={{ padding: ".85rem" }}>
                            {gameData.team2}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchBets?.length > 0 ? (
                          matchBets.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: ".85rem" }}>
                                {bets.user_accounts[0].name}
                              </td>

                              <td style={{ padding: ".85rem" }}>
                                {bets.user_accounts[0].code}
                              </td>

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamA_lay_rate
                                    : bets.teamA_back_rate}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamB_lay_rate
                                    : bets.teamB_back_rate}
                                </td>
                              )}

                              <td style={{ padding: ".85rem" }}>
                                {bets.amount}
                              </td>

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".85rem" }}>Khai</td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>Lagai</td>
                              )}

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {gameData.team1}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>
                                  {gameData.team2}
                                </td>
                              )}

                              <td style={{ padding: ".85rem" }}>
                                {dateTimeFormatter(bets.createdAt)}
                              </td>

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {Number(bets.teamA_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>
                                  {Number(bets.teamA_bet_amount)}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {Number(bets.teamB_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>
                                  {Number(bets.teamB_bet_amount)}
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9">No data found</td>
                          </tr>
                        )}
                        {matchBets?.length > 0 ? (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center"
                              style={{ padding: ".85rem" }}
                            >
                              Total
                            </td>
                            <td></td>
                            <td style={{ padding: ".85rem" }}>{totalMatch1}</td>
                            <td style={{ padding: ".85rem" }}>{totalMatch2}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    {/*<h6 class="bg-secondary text-center text-white text-md mb-0 py-3">
                      SESSION BETS
                      </h6>*/}

                    <table className="table table-responsive table-striped table-bordered">
                      <thead>
                        <th
                          colSpan="9"
                          className="inplay-match-bet-table bg-secondary text-md mb-0 py-3"
                        >
                          SESSION BETS
                        </th>
                      </thead>
                      <thead>
                        <tr>
                          <th style={{ padding: ".85rem" }}>Session</th>
                          <th style={{ padding: ".85rem" }}>Code</th>
                          <th style={{ padding: ".85rem" }}>User</th>
                          <th style={{ padding: ".85rem" }}>Rate</th>
                          <th style={{ padding: ".85rem" }}>Run</th>
                          <th style={{ padding: ".85rem" }}>Mode</th>
                          <th style={{ padding: ".85rem" }}>Amount</th>
                          <th style={{ padding: ".85rem" }}>Dec</th>
                          <th style={{ padding: ".85rem" }}>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData?.length > 0 ? (
                          sessionData.map((session, index) => (
                            <tr key={index}>
                              <td style={{ padding: ".85rem" }}>
                                {session.session}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.user_account.code}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.user_account.name}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.rate}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.runs}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.mode}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.amount}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {session.declare}
                              </td>
                              <td style={{ padding: ".85rem" }}>
                                {dateTimeFormatter(session.createdAt)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9">No data found</td>
                          </tr>
                        )}

                        {sessionData?.length > 0 ? (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center"
                              style={{ padding: ".85rem" }}
                            >
                              Total
                            </td>
                            <td style={{ padding: ".85rem" }}>{totalAmount}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
    </div>
  );
}
