import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CreateNewModal = ({ parentName, parentDataList }) => {
  const [parentData, setParentData] = useState([]);
  const [route, setRoute] = useState("");

  const handleParentChange = (parentId) => {
    const parent = parentDataList?.filter((parent) => {
      return parent.id === parentId;
    });

    setParentData(parent[0]);
  };

  useEffect(() => {
    switch (parentName) {
      case "Super Admin":
        setRoute("/sub-admin/create");
        break;
      case "Sub Admin":
        setRoute("/masters/create");
        break;
      case "Master":
        setRoute("/superagents/create");
        break;
      case "Super Agent":
        setRoute("/agent/create");
        break;
      case "Agent":
        setRoute("/client/create");
        break;
      default:
        break;
    }
  }, [parentDataList]);

  return (
    <div
      className="modal fade"
      id="createNewModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Select {parentName}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {parentDataList ? (
              <div className="form-group">
                <label className="form-label" htmlFor="subAdminParent">
                  Select {parentName}
                </label>
                <select
                  className="form-control"
                  id="subAdminParent"
                  name="subAdminParent"
                  onChange={(e) => {
                    handleParentChange(e.target.value);
                  }}
                >
                  <option value="" defaultValue>
                    Select {parentName}
                  </option>
                  {parentDataList ? (
                    parentDataList?.map((parent, index) => (
                      <option value={parent.id} key={index}>
                        {parent.code} {parent.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Loading...
                    </option>
                  )}
                </select>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              disabled={parentData?.length === 0}
            >
              <Link to={route} state={parentData} style={{ color: "white" }}>
                Proceed
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewModal;
