import React, { Component } from "react";
import PageHeader from "../Components/PageHeader"
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';


export default function LiveReport() {

  const location = useLocation();
  const { game_id, match_datetime, first_team_name, second_team_name } = location.state;
  console.log(location.state);

  const [match, setMatch] = useState([]);

  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  const [sessionModalOpen, setSessionModalOpen] = React.useState(false);
  const [session, setSession] = useState([]);
  const [session_bet_type, setSessionBetType] = useState("");
  const [sessionBetValueNum, setSessionBetValueNum] = useState();
  const [betdetailData, setBetDetail] = useState([]);
  const [SessiondetailData, setSessionDetail] = useState([]);
  const [sessionitemData, setSessionItemData] = useState({});


  async function getListMatch() {
    await axios
      .post(`${process.env.REACT_APP_API_URL}game/activeMatchDetails`, { gameId: game_id }, options)
      .then(async (resp) => {
        if (resp.data) {
          let respData = resp.data.data;
          // console.log("respData===>", respData);

          respData["bm"].map((_matchBav) => {
            setMatch(_matchBav["bm1"]);
          });

          var filterSesssion = [];
          respData["f"].map((_session, index) => {
            filterSesssion.push(_session);
          });
          setSession(filterSesssion);
        }
      })
      .catch((err) => { });
  }

  async function getListBetList() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}all-bet-details`, options)
      .then(async (resp) => {
        if (resp.data) {
          let respData = resp.data.data;
          let filterData = respData.filter((e) => e.matchId == game_id)
          console.log(respData);
          setBetDetail(filterData);
        }
      })
      .catch((err) => { });
  }

  useEffect(() => {
    getListBetList()
    var clear = setInterval(() => getListMatch(), 900);
    return () => {
      clearInterval(clear);
    };
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <PageHeader title="Match & Session Position" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-secondary text-center">
                  <span id="score">
                    <h5 className="card-header" id="scoreMsg">
                      4th T20 Match(1st Inning)
                    </h5>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-5 text-md">
                          <span className="text-secondary">
                            <b id="localTeam">Josh Little* (7)</b>
                          </span>
                          <br />
                          <span className="text-secondary">
                            <b id="visitorTeam">George Dockrell (30)</b>
                          </span>
                        </div>
                        <div className="col-5 text-md">
                          <span className="text-secondary">
                            <b id="localTeam">Ireland 101/9(10.0)</b>
                          </span>
                          <br />
                          <span className="text-secondary">
                            <b id="visitorTeam">Afghanistan 132/6 (11)</b>
                          </span>
                        </div>
                        <div className="col-2 score_image text-md">
                          <span
                            className="text-justify"
                            style={{
                              color: "white",
                              fontSize: "15px !important",
                            }}
                          >
                            <b>Bowler Stop</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <table
                        width="100%"
                        className="table table-bordered"
                        border="0"
                        cellPadding="2"
                        cellSpacing="2"
                      >
                        <thead>
                          <tr>
                            <th  >
                              TEAM </th>
                            <th
                              className="text-red" style={{ width: 40 }}
                            >
                              KHAI
                            </th>
                            <th
                              className="text-blue" style={{ width: 40 }}
                            >
                              LAGAI
                            </th>
                            <th>
                              <b className="text-blue">PLUS</b>/
                              <b className="text-red">MINUS</b>
                            </th>
                            {/* <!-- <td width="35%" align="center" valign="middle" bgcolor="#52b5e1" className="FontTextWhite10px" style={{VerticalAlign:"middle"}}>POSITION</td> --> */}
                          </tr>
                        </thead>
                        <tbody id="team-table-dst">
                          {/*MATCH NAME */}
                          {match && match.length != 0 ? (
                            <>
                              {match.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td
                                        height="35"
                                        align="center"
                                        valign="middle"
                                        bgcolor="#FFF"
                                        className="FontTextBlue"
                                        style={{ VerticalAlign: "middle" }}
                                        selectionid="2"
                                        bettype="L"
                                        teamname="ADELAIDE STRIKERS WBBL"
                                        datatype="match"
                                      >
                                        <span
                                          className="FontTextBlue"
                                          style={{ VerticalAlign: "middle" }}
                                        >
                                          {item.nat}
                                        </span>
                                      </td>

                                      {item.s == "ACTIVE" ? (
                                        <>
                                          <td
                                            align="center"
                                            valign="middle"
                                            bgcolor="#FFF"
                                            style={{
                                              VerticalAlign: "middle",
                                            }}
                                          >
                                            <input
                                              type="button"
                                              name="KRate1"
                                              id="lagai"
                                              value={item.b1}

                                              className="ButtonK"
                                              selectionid="2"
                                              bettype="L"
                                              teamname="ADELAIDE STRIKERS WBBL"
                                              datatype="match"
                                            />
                                          </td>
                                          <td
                                            align="center"
                                            valign="middle"
                                            bgcolor="#FFF"
                                            className="textTeamHead"
                                            style={{
                                              verticalAlign: "middle",
                                              color: "#F00",
                                            }}
                                          >
                                            <input
                                              type="button"
                                              name="LRate1"
                                              // id="LRate1"
                                              id="khaai"
                                              value={item.l1}

                                              className="ButtonL"
                                              selectionid="2"
                                              bettype="K"
                                              teamname="ADELAIDE STRIKERS WBBL"
                                              datatype="match"
                                            />
                                          </td>
                                          <td></td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            align="center"
                                            valign="middle"
                                            bgcolor="#FFF"
                                            style={{
                                              VerticalAlign: "middle",
                                            }}
                                          >
                                            <input
                                              type="button"
                                              name="KRate1"
                                              value={0.0}
                                              className="ButtonK"

                                              selectionid="2"
                                              bettype="L"
                                              teamname="ADELAIDE STRIKERS WBBL"
                                              datatype="match"
                                            />
                                          </td>
                                          <td
                                            align="center"
                                            valign="middle"
                                            bgcolor="#FFF"
                                            className="textTeamHead"
                                            style={{
                                              verticalAlign: "middle",
                                              color: "#F00",
                                            }}
                                          >
                                            <input
                                              type="button"
                                              name="LRate1"
                                              // id="LRate1"

                                              value={0.0}
                                              className="ButtonL"
                                              selectionid="2"
                                              bettype="K"
                                              teamname="ADELAIDE STRIKERS WBBL"
                                              datatype="match"
                                            />
                                          </td>
                                          <td></td>
                                        </>
                                      )}
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td
                                  height="35"
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  className="FontTextBlue"
                                  style={{ VerticalAlign: "middle" }}
                                  selectionid="1"
                                  bettype="L"
                                  teamname="SYDNEY SIXERS WBBL"
                                  datatype="match"
                                >
                                  <span
                                    className="FontTextBlue"
                                    style={{ VerticalAlign: "middle" }}
                                  >
                                    {first_team_name}
                                  </span>
                                  <span
                                    style={{
                                      color: "#00F",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    0
                                  </span>
                                </td>
                                <td
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  style={{ VerticalAlign: "middle" }}
                                >
                                  <input
                                    type="button"
                                    name="KRate1"
                                    id="KRate1"
                                    value="0.00"
                                    className="ButtonK"
                                    selectionid="1"
                                    bettype="L"
                                    teamname="SYDNEY SIXERS WBBL"
                                    datatype="match"
                                  />
                                </td>
                                <td
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  className="textTeamHead"
                                  style={{
                                    color: "#00F",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <input
                                    type="button"
                                    name="LRate1"
                                    id="LRate1"
                                    value="0.00"
                                    className="ButtonL"
                                    selectionid="1"
                                    bettype="K"
                                    teamname="SYDNEY SIXERS WBBL"
                                    datatype="match"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  height="35"
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  className="FontTextBlue"
                                  style={{ VerticalAlign: "middle" }}
                                  selectionid="1"
                                  bettype="L"
                                  teamname="SYDNEY SIXERS WBBL"
                                  datatype="match"
                                >
                                  <span
                                    className="FontTextBlue"
                                    style={{ VerticalAlign: "middle" }}
                                  >
                                    {second_team_name}{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "#00F",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    0
                                  </span>
                                </td>
                                <td
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  style={{ VerticalAlign: "middle" }}
                                >

                                  <input
                                    type="button"
                                    name="KRate1"
                                    id="KRate1"
                                    value="0.00"
                                    className="ButtonK"
                                    selectionid="1"
                                    bettype="L"
                                    teamname="SYDNEY SIXERS WBBL"
                                    datatype="match"
                                  />
                                </td>
                                <td
                                  align="center"
                                  valign="middle"
                                  bgcolor="#FFF"
                                  className="textTeamHead"
                                  style={{
                                    color: "#00F",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <input
                                    type="button"
                                    name="LRate1"
                                    id="LRate1"
                                    value="0.00"
                                    className="ButtonL"
                                    selectionid="1"
                                    bettype="K"
                                    teamname="SYDNEY SIXERS WBBL"
                                    datatype="match"
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6">
                      <table
                        width="100%"
                        className="table table-bordered"
                        border="0"
                        cellPadding="0"
                        cellSpacing="2"
                      >
                        {/* session Heading code */}
                        <thead>
                          <tr>
                            <th

                            >
                              SESSION
                            </th>
                            {/* Not */}
                            <th
                              className="text-red" style={{ width: 40 }}
                            >
                              NOT
                            </th>
                            {/* Yes */}
                            <th
                              className="text-blue" style={{ width: 40 }}
                            >
                              YES
                            </th>
                          </tr>
                        </thead>
                        <tbody id="session-table-dst">
                          {session &&
                            session.map((item) => {
                              return (
                                <>
                                  <tr>
                                    {/* Session Name */}
                                    <td
                                      height="35"
                                      align="center"
                                      valign="middle"
                                      bgcolor="#FFF"
                                      className="textTeamHead"
                                      style={{ color: "#00F" }}
                                    >
                                      <input
                                        type="button"
                                        name="Session1"
                                        id="Session1"
                                        value={item.nat}
                                        className="ButtonSess"
                                      />
                                      <br />
                                      <input
                                        style={{ fontSize: "10px" }}
                                        type="button"
                                        name="Session1"
                                        id="Session13"
                                        value={item.max}
                                        className="ButtonSess"
                                      />
                                    </td>
                                    {/* Not Rate */}
                                    <td
                                      align="center"
                                      valign="middle"
                                      bgcolor="#FFF"
                                      className="textTeamHead"
                                      style={{ color: "#00F" }}
                                    >
                                      <input
                                        type="button"
                                        name="NRun1"
                                        // id="NRun1"
                                        id="session_no"

                                        value={item.l1}
                                        className="ButtonYRun"
                                        runnername="7 OVER RUN SS W"
                                        bettype="Y"
                                        selectionid="380"
                                        datatype="session"
                                      />
                                      <br />
                                      <input
                                        type="button"
                                        name="NRun1"
                                        id="NRun1"
                                        value={item.ls1}
                                        className="ButtonYRate"
                                      />
                                    </td>
                                    {/* Yes Rate */}
                                    <td
                                      align="center"
                                      valign="middle"
                                      bgcolor="#FFF"
                                      className="textTeamHead"
                                      style={{ color: "#00F" }}
                                    >
                                      <input
                                        type="button"
                                        name="YRun1"
                                        // id="YRun1"
                                        value={item.b1}
                                        id="session_yes"

                                        className="ButtonNRun"
                                        bhav="0.00"
                                        selectionid="380"
                                        datatype="session"
                                      />
                                      <br />
                                      <input
                                        type="button"
                                        name="YRun1"
                                        id="YRun1"
                                        value={item.bs1}
                                        className="ButtonNRate"
                                        datatype="session"
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                      {/* </td>
                          </tr>
                        
                          <tr>
                            <td valign="top"></td>
                          </tr>
                        </table> */}
                    </div>
                  </div>



                  <div className="row" id="match_bets">
                    <div className="col-lg-12"> 
                      <table className="table table-bordered table-striped table-hover table-responsive-md">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Rate</th>
                            <th>Team</th>
                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Date &amp; Time</th>
                          </tr>
                        </thead>
                        <tbody id="load_data">
                          {betdetailData.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.odds}</td>
                                  <td>{item.teamName}</td>
                                  <td>{item.stack}</td>
                                  <td>{item.isKhai == true ? "Khai" : "Lagai"} </td>
                                  <td>
                                    <Moment format="DD/MM/YYYY - hh:mm:ss" add={{ hours: 12 }}>
                                      {item.createdAt}
                                    </Moment>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                          )}

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: "none" }}
                    id="session_bets"
                  >
                    <div className="col-lg-12">
                      <table
                        className="table table-bordered table-striped table-hover table-responsive-md"
                        id="matchBetsTable"
                      >
                        <thead>
                          <tr>
                          <th>Client</th>
                            <th>Code</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Team</th>
                            <th>Team1</th>
                            <th>Team2</th>
                            <th>Date &amp; Time</th>
                          </tr>
                        </thead>
                        <tbody id="session_bets_slip"></tbody>
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </>
  );
}