import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./master.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import useGetMasterList from "../../hooks/Master/useGetMasterList";
import useGetSubAdminList from "../../hooks/SubAdmin/useGetSubAdminList";
import useUpdateAccountStatus from "../../hooks/useUpdateAccountStatus";
import { useQueryClient } from "react-query";
import CreateNewModal from "../../Components/createNewModal";

export default function MasterList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: masterListData } = useGetMasterList();
  const { data: SubAdminListData } = useGetSubAdminList();
  const { mutate: updateAccountStatus } = useUpdateAccountStatus();

  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [parentDataList, setParentDataList] = useState([]);
  const accountType = localStorage.getItem("account");

  useEffect(() => {
    const listData = masterListData?.data;
    const parentData = SubAdminListData?.data;
    setParentDataList(parentData);

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
      setCompleteData(listData);
      // console.log(listData);
    }
  }, [masterListData]);

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
 
   const [isFirstPage, setIsFirstPage] = useState(true);
   const [isLastPage, setIsLastPage] = useState(false);
 
   const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
   const handlePrevious = () => {
     if (currentPage > 1) {
       paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

       setIsLastPage(false);
     }
   };
 
   const handleNext = () => {
     if (currentPage < Math.ceil(data.length / itemsPerPage)) {
       paginate(currentPage + 1);
       setIsFirstPage(false);
      setIsLastPage(currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2);

     }
   };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers(data?.map((item) => item.id));
    } else {
      setSelectedUsers([]);
    }

    setSelectAll(isChecked);
  };

  const masterAllActive = () => {
    const acc_type = "master";
    const status = true;

    selectedUsers.forEach((id) => {
      const payload = { acc_type, id, status };

      updateAccountStatus(payload, {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success("Clients activated successfully");
          queryClient.invalidateQueries("get-master-list");
        },
        onError: () => {
          toast.error("Failed to activate client");
        },
      });
    });
  };

  const masterAllDeactive = () => {
    const acc_type = "master";
    const status = false;

    selectedUsers.forEach((id) => {
      const payload = { acc_type, id, status };

      updateAccountStatus(payload, {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success("Clients deactivated successfully");
          queryClient.invalidateQueries("get-master-list");
        },
        onError: () => {
          toast.error("Failed to deactivate client");
        },
      });
    });
  };

  const handleUserSelect = (e, userId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const showResponsiveCols = (action, item) => {
    let existingElement = document.getElementById(`row${item.code}`);

    let showBtn = document.getElementById(`showBtn${item.code}`);
    let hideBtn = document.getElementById(`hideBtn${item.code}`);

    if (existingElement) {
      if (action == "show") {
        let tr = document.createElement("tr");
        tr.setAttribute("id", `showRow${item.code}`);

        let tdTitle = document.createElement("td");
        tdTitle.setAttribute("colspan", "2");
        tdTitle.style.borderRight = "none";

        let tdValue = document.createElement("td");
        tdValue.setAttribute("colspan", "10");
        tdValue.style.borderLeft = "none";

        let title1 = document.createElement("div");
        title1.classList.add("dataTitle");
        title1.textContent = "Status ";

        let value1 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title11 = document.createElement("div");
        title11.classList.add("dataTitle");
        let statusSpan = document.createElement("span");
        statusSpan.textContent = item.status ? "Active" : "Inactive";
        if (item.status) {
          statusSpan.classList.add("active_bg");
        } else {
          statusSpan.classList.add("inactive_bg");
        }
        title11.appendChild(statusSpan);

        let value11 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title2 = document.createElement("div");
        title2.classList.add("dataTitle");
        title2.textContent = "Limit: ";

        let value2 = document.createElement("div");
        value2.classList.add("dataValue");
        value2.textContent = item.limit;

        let title3 = document.createElement("div");
        title3.classList.add("dataTitle");
        title3.textContent = "S. Com: ";

        let value3 = document.createElement("div");
        value3.classList.add("dataValue");
        value3.textContent = item.session_commission;

        let title4 = document.createElement("div");
        title4.classList.add("dataTitle");
        title4.textContent = "M. Com: ";

        let value4 = document.createElement("div");
        value4.classList.add("dataValue");
        value4.textContent = item.match_commission;

        let title5 = document.createElement("div");
        title5.classList.add("dataTitle");
        title5.textContent = "Share: ";

        let value5 = document.createElement("div");
        value5.classList.add("dataValue");
        value5.textContent = item.share;

        let title6 = document.createElement("div");
        title6.classList.add("dataTitle");
        title6.textContent = "Contact: ";

        let value6 = document.createElement("div");
        value6.classList.add("dataValue");
        value6.textContent = item.mobile;

        let title7 = document.createElement("div");
        title7.classList.add("dataTitle");
        title7.textContent = "Password: ";

        let value7 = document.createElement("div");
        value7.classList.add("dataValue");
        value7.textContent = item.password;

        let title8 = document.createElement("div");
        title8.classList.add("dataTitle");
        title8.textContent = "Name: ";

        let value8 = document.createElement("div");
        value8.classList.add("dataValue");
        value8.textContent = item.name;

        let title9 = document.createElement("div");
        title9.classList.add("dataTitle");
        title9.textContent = "CODE: ";

        let value9 = document.createElement("div");
        value9.classList.add("dataValue");
        value9.textContent = item.code;

        let screenWidth = window.innerWidth;

        if (screenWidth < 992) {
          tdTitle.appendChild(title1);
          tdValue.appendChild(value1);
        }

        if (screenWidth < 992) {
          tdTitle.appendChild(title11);
          tdValue.appendChild(value11);
        }
        if (screenWidth < 912) {
          tdTitle.insertBefore(title2, tdTitle.firstChild);
          tdValue.insertBefore(value2, tdValue.firstChild);
        }

        if (screenWidth < 832) {
          tdTitle.insertBefore(title3, tdTitle.firstChild);
          tdValue.insertBefore(value3, tdValue.firstChild);
        }

        if (screenWidth < 752) {
          tdTitle.insertBefore(title4, tdTitle.firstChild);
          tdValue.insertBefore(value4, tdValue.firstChild);
        }

        if (screenWidth < 672) {
          tdTitle.insertBefore(title5, tdTitle.firstChild);
          tdValue.insertBefore(value5, tdValue.firstChild);
        }

        if (screenWidth < 592) {
          tdTitle.insertBefore(title6, tdTitle.firstChild);
          tdValue.insertBefore(value6, tdValue.firstChild);
        }

        if (screenWidth < 512) {
          tdTitle.insertBefore(title7, tdTitle.firstChild);
          tdValue.insertBefore(value7, tdValue.firstChild);
        }

        if (screenWidth < 432) {
          tdTitle.insertBefore(title8, tdTitle.firstChild);
          tdValue.insertBefore(value8, tdValue.firstChild);
        }

        if (screenWidth < 352) {
          tdTitle.insertBefore(title9, tdTitle.firstChild);
          tdValue.insertBefore(value9, tdValue.firstChild);
        }

        tr.appendChild(tdTitle);
        tr.appendChild(tdValue);

        if (existingElement.parentNode != null) {
          existingElement.parentNode.insertBefore(
            tr,
            existingElement.nextSibling
          );
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      } else {
        let row = document.getElementById(`showRow${item.code}`);
        if (row) {
          row.remove();
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      }
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Master</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/home">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Master</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="form-group">
                    {accountType === "sub_admin" ? (
                      <Link
                        type="button"
                        to="/masters/create"
                        className="btn btn-primary mr-2 mt-2"
                      >
                        New{" "}
                        <i className="fa fa-plus-circle">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </i>
                      </Link>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary mr-2 mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#createNewModal"
                      >
                        New{" "}
                        <i className="fa fa-plus-circle">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </i>
                      </button>
                    )}

                    <button
                      className={`btn button-check btn-success mr-2 mt-2 ${
                        selectedUsers?.length === 0 ? "disabled" : ""
                      }`}
                      id="allActive"
                      onClick={masterAllActive}
                    >
                      All Active
                    </button>

                    <button
                      className={`btn button-check btn-danger mr-2 mt-2 ${
                        selectedUsers?.length === 0 ? "disabled" : ""
                      }`}
                      id="allInActive"
                      onClick={masterAllDeactive}
                    >
                      All DeActivate{" "}
                      <i className="fa fa-ban">
                        <FontAwesomeIcon icon={faBan} />
                      </i>
                    </button>

                    {accountType === "sub_admin" || accountType === "admin" ? (
                      <Link
                        to="/masters/limitUpdate"
                        className="btn btn-primary mr-2 mt-2"
                      >
                        Limit Update
                      </Link>
                    ) : null}
                  </div>
                </div>

                <div className="card-body">
                  <div className="table">
                    <div className="row">
                      <div className="parent-container">
                        <div className="col-6">
                          <div
                            className="label-1 dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  setItemsPerPage(e.target.value);
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="input-container col-6 d-flex gap-2 justify-content-end">
                          <label htmlFor="search">Search:</label>
                          <input
                            type="text"
                            id="search"
                            className="form-control form-control-sm w-auto ml-0"
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (value?.length > 0) {
                                searchFilter(value);
                              } else {
                                setData(completeData);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive table-striped dataTable no-footer dtr-inline"
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th className="thr-control">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="toggle-button-size-page">#</th>
                          <th className="mobile-d-10">
                            Code<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-9">
                            Name<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-8">
                            Sub Admin<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-7">
                            Password<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-6">
                            Contact<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-5">
                            Limit<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-4">
                            M.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-3">
                            S.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-2">
                            Share<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-1">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data?.length !== 0 ? (
                          <>
                            {currentItems?.map((item, index) => (
                              <tr key={index} id={`row${item.code}`}>
                                <td className="dtr-control">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    checked={selectedUsers.includes(item.id)}
                                    onChange={(e) => {
                                      handleUserSelect(e, item.id);
                                    }}
                                  />
                                  <div
                                    className="dynamicControl showButton"
                                    id={`showBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("show", item);
                                    }}
                                  >
                                    +
                                  </div>
                                  <div
                                    className="dynamicControl hide"
                                    id={`hideBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("hide", item);
                                    }}
                                  >
                                    -
                                  </div>
                                </td>
                                <td>
                                  <span className="mr-2">{index + 1}</span>
                                  <div className="btn-group">
                                    <button
                                      style={{
                                        padding: "2px 4px",
                                        lineHeight: "1.5",
                                      }}
                                      type="button"
                                      className="btn btn-outline-secondary dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="sr-only">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <div className="dropdown-menu" role="menu">
                                      <Link
                                        className="dropdown-item"
                                        to={{
                                          pathname: "/masters/update",
                                        }}
                                        state={item}
                                      >
                                        Edit
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          selectedUsers.push(item.id);
                                          {
                                            item.status
                                              ? masterAllDeactive()
                                              : masterAllActive();
                                          }
                                        }}
                                      >
                                        {item.status ? "Inactive" : "Activate"}
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to={{
                                          pathname:
                                            "/masters/child-limitUpdate",
                                        }}
                                        state={item}
                                      >
                                        Super Agent Limit Update
                                      </Link>
                                    </div>
                                  </div>
                                </td>

                                <td className="mobile-s-10">{item.code}</td>
                                <td className="mobile-d-9">{item.name}</td>
                                <td className="mobile-d-8">{item.parent}</td>
                                <td className="mobile-d-7">{item.password}</td>
                                <td className="mobile-d-6">{item.mobile}</td>
                                <td className="mobile-d-5">{Math.round(item.limit)}</td>
                                <td className="mobile-d-4">
                                  {item.match_commission}
                                </td>
                                <td className="mobile-d-3">
                                  {item.session_commission}
                                </td>
                                <td className="mobile-d-2">{item.share}</td>
                                <td className="mobile-d-1">
                                  {item.status ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-danger"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={12} className="text-center p-5">
                              <h6 className="p-5">No records to display</h6>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                   <div className="col-sm-12 col-md-5">
                     <div className="dataTables_info" id="example1_info">
                       Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                       {completeData.length} entries
                     </div>
                   </div>
                   <div className="col-sm-12 col-md-7">
                     <div
                       className="dataTables_paginate paging_simple_numbers"
                       id="example1_paginate"
                     >
                       <ul className="pagination">
                         <li
                           className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""

                           }`}
                           id="example1_previous"
                         >
                           <a
                             tabIndex="0"
                             className="page-link"
                             onClick={handlePrevious}
                           >
                             Previous
                           </a>
                         </li>
                        {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li
                                  key={index}
                                  className="page-item"
                                >
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                   {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }                        )}
                        
                         <li
                           className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""

                           }`}
                           id="example1_next"
                         >
                           <a
                             tabIndex="0"
                             className="page-link"
                             onClick={handleNext}
                           >
                             Next
                           </a>
                         </li>
                       </ul>
                     </div>
                   </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {accountType !== "sub_admin" ? (
        <CreateNewModal
          parentName="Sub Admin"
          parentDataList={parentDataList}
        />
      ) : null}
    </div>
  );
}
