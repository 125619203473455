import React, { useState, useEffect } from "react";
import "../AgentMaster/CreateNewAgent.css";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function CasinoPlusMinusTable() {
  const navigate = useNavigate();
  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }

  const [agentName, setAgentName] = useState("");
  const [agentNumber, setAgentNumber] = useState("");
  const [agentPassword, setAgentPassword] = useState("");
  const [agentReference, setAgentReference] = useState("");
  const [agentLimit, setAgentLimit] = useState(0);
  const [agentShare, setAgentShare] = useState(0);
  const [agentCasinoShare, setAgentCasinoShare] = useState(0);
  const [agentMobileShare, setAgentMobileShare] = useState(0);
  const [agentCommission, setAgentCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [agentMatchCommission, setAgentMatchCommission] = useState(0);
  const [agentSessionCommission, setAgentSessionCommission] = useState(0);
  const [agentCassinoCommission, setAgentCassinoCommission] = useState(0);

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "agent",
      name: agentName,
      reference: agentReference,
      password: String(agentPassword),
      parent: parentData.id,
      mobile: agentNumber,
      limit: agentLimit,
      share: agentShare,
      cassino_share: agentCasinoShare,
      mobile_share: agentMobileShare,
      commission: agentCommission,
      match_commission: agentMatchCommission,
      session_commission: agentSessionCommission,
      cassino_commission: agentCassinoCommission,
    };
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match & Session Plus Minus Report</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/agent">Agent</Link>
                </li>
                <li className="breadcrumb-item active"> 
                  <a>Match & Session Plus Minus Report</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card mt-3">
        <div className="card-header bg-white">
          <h6 className="mb-0">MATCH CODE : 56558</h6>
        </div>
        <div className="card-body bg-white">
          <div className="card">
            <div>
              <div className="card-header bg-indigo py-2 text-white">
                ADMIN : 5822
              </div>
              <div className="card-body">
                <div className="card">
                  <div>
                    <div className="card-header bg-indigo py-2 text-white">
                      SUBADMIN NAME : 5556
                    </div>
                    <div className="card-body">
                      <div className="card">
                        <div>
                          <div className="card-header bg-indigo py-2 text-white">
                            MASTER NAME : 4654
                          </div>
                          <div className="card-body">
                            <div className="card">
                              <div>
                                <div className="card-header bg-purple py-2 text-white">
                                  SUPERAGENT NAME : 4878
                                </div>
                                <div className="card-body">
                                  <div className="card">
                                    <div>
                                      <div className="card-header bg-primary py-2">
                                        AGENT NAME : 4688
                                      </div>
                                      <div className="card-body">
                                        <div className="table mt-3">
                                          <div className="table-responsive">
                                            <table className="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th colSpan="9">Client</th>
                                                  <th colSpan="7">Agent</th>
                                                  <th colSpan="7">
                                                    Super Agent
                                                  </th>
                                                  <th colSpan="7">Master</th>
                                                  <th colSpan="7">Sub Admin</th>
                                                  <th colSpan="7">
                                                    Super Admin
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <th>Client</th>
                                                  <th>M Amt</th>
                                                  <th>S Amt</th>
                                                  <th>M Comm</th>
                                                  <th>S Comm</th>
                                                  <th>T Comm</th>
                                                  <th>Net Amt</th>
                                                  <th>C Mob</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                  <th>M Com</th>
                                                  <th>S Com</th>
                                                  <th>T Com</th>
                                                  <th>Net Amt</th>
                                                  <th>Shr Amt</th>
                                                  <th>Mob App</th>
                                                  <th>Final</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>5555</td>
                                                  <td>efefe</td>
                                                  <td>dfddf</td>
                                                  <td>fdddf</td>
                                                  <td>ffgfg</td>
                                                  <td>gffgfg</td>
                                                  <td>fdfdfds</td>
                                                  <td>fgfgf</td>
                                                  <td>fgffdg</td>
                                                  <td>gfgf</td>
                                                  <td>ffgdf</td>
                                                  <td>nggff</td>
                                                  <td>fggdfg</td>
                                                  <td>fgdgdfg</td>
                                                  <td>ffgdf</td>
                                                  <td>hhgfhd</td>
                                                  <td>bfbdfbf</td>
                                                  <td>gbfgb</td>
                                                  <td>fgfg</td>
                                                  <td>fgfd</td>
                                                  <td>fgfgfg</td>
                                                  <td>bggb</td>
                                                  <td>ghgghg</td>
                                                  <td>fgfgf</td>
                                                  <td>fgffdg</td>
                                                  <td>gfdfgf</td>
                                                  <td>bgggb</td>
                                                  <td>ghghgh</td>
                                                  <td>fdgdfgd</td>
                                                  <td>ffgf</td>
                                                  <td>ffgfg</td>
                                                  <td>fgdf</td>
                                                  <td>vbvbcv</td>
                                                  <td>ghhf</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                </tr>

                                                <tr>
                                                  <td>Total</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                  <td>vbvbvcbbc</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
